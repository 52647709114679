import "./index.scss";
import Scrollbars from "react-custom-scrollbars";
import {
  Input,
  Button,
  Pagination,
  message,
  Checkbox,
  Menu,
  Dropdown,
} from "antd";
import { useEffect, useState, useRef } from "react";

import {
  CaretUpOutlined,
  CaretDownOutlined,
  DownOutlined,
  PlusOutlined,
  EditFilled,
  BookFilled,
  DeleteFilled,
  DiffOutlined,
} from "@ant-design/icons";
import MySpin from "../../../components/MySpin";
import { useSelector } from "react-redux";
import Empty from "../../../components/Empty";
import projectApi from "../../../apis/project";
import DialogDrawer from "./components/DialogDrawer";
import BatchDrawer from "./components/BatchDrawer";
import MessageModal from "../../../components/MessageModal";
import DetailDrawer from "./components/DetailDrawer ";
import MetaDataCard from "./components/MetaDataCard";
import BloodRelation from "./components/BloodRelation";

const CheckboxGroup = Checkbox.Group;
const orderDataList = [
  { label: "中文名称", key: "chi_name" },
  { label: "英文名称", key: "eng_name" },
  { label: "创建时间", key: "created_time" },
  { label: "更新时间", key: "updated_time" },
];

const MetaData = (props) => {
  const dicCodeInfoList = useSelector((state) => state.Project.dicCodeInfoList);
  // 元数据类型
  const metaDataType = dicCodeInfoList?.DG_METADATA_TYPE?.map((item) =>
    Object.keys(item)
  ).flat();
  // 数据分层主题域映射
  const layerSubList = useSelector((state) => state.Project.layerSubList);

  // 存储类型
  const newDBType = dicCodeInfoList?.DG_DB_TYPE
    ? [
        {
          all: "全部",
        },
        ...dicCodeInfoList?.DG_DB_TYPE,
      ]
    : [];
  const DBType = newDBType?.map((item) => Object.keys(item)).flat();

  // 状态
  const statuDataType = dicCodeInfoList?.DG_METADATA_STAT?.map((item) =>
    Object.keys(item)
  ).flat();

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [topLoading, setTopLoading] = useState(false);

  const [layerList, setLayerList] = useState([]); // 数据分层列表
  const [dialoglayerList, setDialogLayerList] = useState([]); // 数据分层列表-dialogDrawer
  const [subList, setSubList] = useState([]); // 主题域列表
  const [subId, setSubId] = useState(""); // 主题域标准版本
  const [subChildList, setSubChildList] = useState([]); // 子主题域列表
  const [metaList, setMetaList] = useState([]); // 元数据查询结果列表

  const [metaDataCode, setMetaDataCode] = useState("WEB"); // 元数据类型选择
  const [layerCode, setLayerCode] = useState("all"); // 数据分层选择
  const [subCode, setSubCode] = useState("all"); // 主题域选择
  const [subName, setSubName] = useState(""); // 主题域选择
  const [subChildCode, setSubChildCode] = useState("all"); // 子主题域选择
  const [allSubChildCode, setAllSubChildCode] = useState([]); // 子主题域所有选择
  const [statuCode, setStatuCode] = useState("NORMAL"); // 状态选择
  const [DBTypeCode, setDBTypeCode] = useState("all"); // 存储类型选择
  const [metadataName, setMetadataName] = useState(""); // 元数据名称
  const [newmetadataName, setNewMetadataName] = useState(""); // 监听元数据名称
  const [tagName, setTagName] = useState(""); // 标签
  const [newtagName, setNewTagName] = useState(""); // 监听标签名称
  const [orderList, setOrderList] = useState(["updated_time", "desc"]); // 筛选条件
  const [upOrderCode, setUpOrderCode] = useState({}); // 上排序选择
  const [downOrderCode, setDownOrderCode] = useState({}); // 下排序选择
  const [DialogDrawerVisible, setDialogDrawerVisible] = useState(false); // 录入修改元数据表单
  const [drawerTitle, setDrawerTitle] = useState(""); // 录入修改元数据表单标题
  const [addNew, setAddNew] = useState(true); // 录入新增元数据
  const [defaultData, setDefaultData] = useState(null); // 修改元数据回显

  const [checkAll, setCheckAll] = useState(false); // 控制全选
  const [checkedList, setCheckedList] = useState([]);
  const [plainOptionList, setPlainOptionList] = useState([]); // 全选反选绑定数据
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(true); // 控制反选

  const [importDialogVisible, setImportDialogVisible] = useState(false); // 批量操作元数据
  const [okText, setOkText] = useState(""); // 批量操作确认文字
  const [onTitle, setOnTitle] = useState(""); // 批量操作标题

  const [DetailDrawerVisible, setDetailDrawerVisible] = useState(false); // 查看元数据详情
  const [DetailDrawerLoading, setDetailDrawerLoading] = useState(false); // 查看元数据loading
  const [metaDrawerLoading, setMetaDrawerLoading] = useState(false); // 修改元数据loading
  const [chooseList, setChooseList] = useState([]);
  const [DetailDrawerId, setDetailDrawerId] = useState(""); // 元数据详情id
  const [metadataDetailInfo, setMetadataDetailInfo] = useState({}); // 元数据详情数据
  const [metadataVersoList, setMetadataVersonList] = useState([]); // 元数据详情版本列表
  const [relationList, setRelationList] = useState([]); // 血缘关系列表
  const [relationFlag, setRelationFlag] = useState(false); // 血缘关系展示切换
  const [relationVisible, setRelationVisible] = useState(false); //  血缘关系
  const [metaDataInfo, setMetaDataInfo] = useState({}); //  单个元数据的血缘关系
  const [relationLoading, setRelationLoading] = useState(true); // 血缘关系加载
  const [tagList, setTagList] = useState([]); // 标签列表
  const [MetadataStorageList, setMetadataStorageList] = useState([]); // 数据存储列表
  const [newDBTypeCode, setNewDBTypeCode] = useState(null); // 新增修改存储类型选择

  const [fileList, setFileList] = useState([]); //导入元数据
  const [importFile, setImportFile] = useState(false); // 正在进行导入操作判断
  const [copiedFlag, setCopiedFlag] = useState(false); // 复制点击判断
  const [copied, setCopied] = useState({}); // 实现复制功能
  const listHeight = useRef();

  const menu = (
    <Menu
      items={[
        // {
        //   key: "1",
        //   label: <div>编辑</div>,
        //   onClick: () => {
        //     // setOkText("保存");
        //     // setOnTitle("批量编辑元数据");
        //     // if (checkedList.length !== 0) {
        //     //   setImportDialogVisible(true);
        //     // } else if (checkedList.length === 0) {
        //     //   message.info("请选择需要编辑的元数据");
        //     // }
        //   },
        //   icon: <EditFilled style={{ color: "#787878" }} />,
        // },
        {
          key: "1",
          label: <div>废弃</div>,
          onClick: () => {
            if (checkedList.length !== 0) {
              const data = checkedList?.map((item) => item.metadata_id);
              batchDelete(data);
            } else if (checkedList.length === 0) {
              message.info("请选择需要废弃的元数据");
            }
          },
          icon: <DeleteFilled style={{ color: "#787878" }} />,
        },
        {
          key: "2",
          label: <div>添加标签</div>,
          onClick: () => {
            setOkText("添加");
            setOnTitle("批量添加标签");
            if (checkedList.length !== 0) {
              setImportDialogVisible(true);
            } else if (checkedList.length === 0) {
              message.info("请选择需要添加标签的元数据");
            }
          },
          icon: <BookFilled style={{ color: "#787878" }} />,
        },
      ]}
    />
  );
  // 获取数据分层标准版本
  const getLayerVersonList = () => {
    projectApi
      .getVersonList(
        { dg_std_type: "DATA_LAYER" },
        { orders: [{ order_name: "std_ver_id", order_by: "desc" }] }
      )
      .then((res) => {
        if (res.code === 200) {
          if (res.data.total !== 0) {
            getLayerPublishedList(res.data.items[0].ver_no);
          }
        }
      });
  };

  // 获取数据分层已发布标准
  const getLayerPublishedList = (id) => {
    projectApi
      .getLayerPublishedList({ ver_no: id }, { orders: [] })
      .then((res) => {
        if (res.code === 200) {
          const data = res.data.items.map((item) => {
            return {
              data_layer_id: item.data_layer_id,
              data_layer_name: item.data_layer_name,
            };
          });
          let newArr = [];
          let arrId = [];
          for (let item of data) {
            if (arrId.indexOf(item["data_layer_id"]) === -1) {
              arrId.push(item["data_layer_id"]);
              newArr.push(item);
            }
          }
          newArr.unshift({
            data_layer_id: "all",
            data_layer_name: "全部",
          });
          setDialogLayerList(data);
          setLayerList(newArr);
          setTopLoading(false);
        } else {
          setLayerList([]);
          setTopLoading(false);
        }
      });
  };

  // 获取主题域标准版本
  const getVersonList = () => {
    projectApi
      .getVersonList(
        { dg_std_type: "SUB" },
        { orders: [{ order_name: "std_ver_id", order_by: "desc" }] }
      )
      .then((res) => {
        if (res.code === 200) {
          if (res.data.total !== 0) {
            setSubId(res.data.items[0].ver_no);
          }
        }
      });
  };
  // 获取主题域已发布标准
  const getPublishedList = () => {
    projectApi
      .getSubPublishedList(
        { ver_no: subId, data_layer_id: layerCode },
        { orders: [] }
      )
      .then((res) => {
        if (res.code === 200) {
          const data = res.data.items.map((item) => {
            return {
              sub_id: item.sub_id,
              sub_name: item.sub_name,
            };
          });
          let newArr = [];
          let arrId = [];
          for (let item of data) {
            if (arrId.indexOf(item["sub_id"]) === -1) {
              arrId.push(item["sub_id"]);
              newArr.push(item);
            }
          }
          newArr.unshift({
            sub_id: "all",
            sub_name: "全部",
          });
          setSubList(newArr);
          // setSubCode(newArr[0].sub_id);
        } else {
          setSubList([]);
        }
      });
  };

  // 获取子主题域请求
  const getChild = () => {
    projectApi
      .getSubStandardList(subCode, {
        publish_type: "PUBLISHED",
        data_layer_name: subName,
        page: 1,
        size: 100,
      })
      .then((res) => {
        if (res.code === 200) {
          const data = res.data.items.map((item) => {
            return {
              sub_id: item.sub_id,
              sub_name: item.sub_name,
            };
          });
          let newArr = [];
          let arrId = [];
          for (let item of data) {
            if (arrId.indexOf(item["sub_id"]) === -1) {
              arrId.push(item["sub_id"]);
              newArr.push(item);
            }
          }
          const AllSubChildCode = newArr?.map((item) => item?.sub_id);
          setAllSubChildCode(AllSubChildCode);
          newArr.unshift({
            sub_id: "all",
            sub_name: "全部",
          });
          setSubChildList(newArr);

          // setSubChildCode(newArr[0].sub_id);
        } else {
          setSubChildList([]);
        }
      });
  };

  // 获取推荐标签列表
  const getAllMetadataTagList = () => {
    projectApi.getMetadataTagList().then((res) => {
      if (res.code === 200) {
        setTagList(res.data.items);
      } else {
        setTagList([]);
      }
    });
  };

  // 数据存储列表查询
  const getMetadataStorage = (id) => {
    projectApi.getMetadataStorage(id).then((res) => {
      if (res.code === 200) {
        setMetadataStorageList(res.data.items);
      } else {
        setMetadataStorageList([]);
      }
    });
  };

  useEffect(() => {
    if (newDBTypeCode !== null) {
      getMetadataStorage(newDBTypeCode);
    } else {
      getMetadataStorage();
    }
  }, [newDBTypeCode]);

  useEffect(() => {
    setTopLoading(true);
    getLayerVersonList(); // 获取数据分层最新标准
    getVersonList(); // 获取主题域最新标准
    getAllMetadataTagList(); // 获取标签列表
  }, []);

  useEffect(() => {
    if (layerCode !== "all") {
      getPublishedList();
    }
  }, [layerCode]);

  useEffect(() => {
    if (subCode !== "all") {
      getChild();
    }
  }, [subCode]);

  // 分页
  const pageChange = (page, size) => {
    setPageNumber(page);
    setPageSize(size);
  };

  // 元数据查询
  const getMetadataList = () => {
    setLoading(true);
    let params = {
      metadata_type_code: metaDataCode, // 元数据类型
      metadata_stat_code: statuCode, // 生效标识
      page: pageNumber,
      size: pageSize,
      order_list: orderList.toString(),
    };
    if (layerCode !== "all") {
      params.data_layer_id = layerCode;
    }
    if (subCode !== "all") {
      params.sub_id = subCode;
    }
    if (subChildCode === "all" && subCode !== "all") {
      params.sub_id = [subCode, ...allSubChildCode];
    }
    if (subChildCode !== "all" && subCode !== "all") {
      params.sub_id = [subCode, subChildCode];
    }

    if (newmetadataName !== "") {
      params.metadata_type_name = newmetadataName;
    }
    if (newtagName !== "") {
      params.tag_name = newtagName;
    }
    if (DBTypeCode !== "all") {
      params.db_type_code = DBTypeCode;
    }
    projectApi.getMetadataList(params).then((res) => {
      if (res.code === 200) {
        setMetaList(res.data.items);
        setTotal(res.data.total);
        setPlainOptionList(res.data.items);
      } else {
        setPlainOptionList([]);
        setMetaList([]); // 元数据查询结果列表
        setTotal(0);
      }
    });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    // 清除定时器
    return () => clearInterval(timer);
  }, [metaList]);

  // 排序 正序
  const sortOrder = (item, id) => {
    setOrderList([item.key, id]);
    setPageNumber(1);
  };

  // 元数据查询
  useEffect(() => {
    getMetadataList();
  }, [
    metaDataCode,
    layerCode,
    DBTypeCode,
    newmetadataName,
    newtagName,
    statuCode,
    subCode,
    subChildCode,
    orderList,
    pageNumber,
    pageSize,
  ]);

  // 全选控制小选
  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? plainOptionList : []);
    setIndeterminate(false);
    setChecked(e.target.checked ? true : false);
    setCheckAll(e.target.checked);
  };

  // 小选控制全选
  const checkChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptionList.length);
    setCheckAll(list.length === plainOptionList.length);
  };
  // 重置
  const resetAll = () => {
    setMetaDataCode("WEB");
    setLayerCode("all");
    setSubCode("all");
    setSubList([]);
    setSubChildList([]);
    setDBTypeCode("");
    setStatuCode("NORMAL");
    setDBTypeCode("all");
    setSubChildCode("all");
    setMetadataName("");
    setTagName("");
    setNewMetadataName("");
    setNewTagName("");
  };

  // 获取元数据详情的信息
  // const getmetaDataInfo = (flag, id, metaDataInfo, code) => {
  //   setDetailDrawerLoading(true);
  //   setMetaDrawerLoading(true);
  //   if (flag === false && Object.keys(metaDataInfo)?.length !== 0) {
  //     getMetadataVerson(metaDataInfo.metadata_id);
  //   }
  //   // metaDataInfo && setDetailDrawerId(metaDataInfo.metadata_id);
  //   if (flag === false) {
  //     // 获取标签信息
  //     projectApi.getMetadataTag({ metadata_ver_id: id }).then((res) => {
  //       if (res.code === 200) {
  //         setChooseList(res.data[id]);
  //       } else {
  //         setChooseList([]);
  //       }
  //     });
  //   }

  //   // 获取元数据详情
  //   if (code === "WEB") {
  //     projectApi.getMetadataWeb({ metadata_ver_id: id }).then((res) => {
  //       if (res.code === 200) {
  //         flag ? layerHandle(false, res.data) : metadataDetail(res.data, id);
  //       } else {
  //         message.info("获取元数据详情失败");
  //       }
  //     });
  //   } else if (code === "API") {
  //     projectApi.getMetadataApi({ metadata_ver_id: id }).then((res) => {
  //       if (res.code === 200) {
  //         flag ? layerHandle(false, res.data) : metadataDetail(res.data, id);
  //       } else {
  //         message.info("获取元数据详情失败");
  //       }
  //     });
  //   } else if (code === "DS") {
  //     projectApi.getMetadataDb({ metadata_ver_id: id }).then((res) => {
  //       if (res.code === 200) {
  //         flag ? layerHandle(false, res.data) : metadataDetail(res.data, id);
  //       } else {
  //         message.info("获取元数据详情失败");
  //       }
  //     });
  //   } else if (code === "TABLE") {
  //     projectApi.getMetadataTable({ metadata_ver_id: id }).then((res) => {
  //       if (res.code === 200) {
  //         flag ? layerHandle(false, res.data) : metadataDetail(res.data, id);
  //       } else {
  //         message.info("获取元数据详情失败");
  //       }
  //     });
  //   }
  // };

  // 获取版本
  const getMetadataVerson = (id) => {
    projectApi.getMetadataVerson({ metadata_id: id }).then((res) => {
      if (res.code === 200) {
        setMetadataVersonList(res.data);
        setDetailDrawerVisible(true);
      } else {
        setMetadataVersonList([]);
        setDetailDrawerVisible(true);
      }
    });
  };

  // 获取元数据详情的信息
  const getmetaDataInfo = (flag, id, metaDataInfo, code) => {
    if (flag === false && metaDataInfo?.metadata_id !== undefined) {
      getMetadataVerson(metaDataInfo?.metadata_id);
    }

    setDetailDrawerLoading(true);
    setMetaDrawerLoading(true);
    let promiseChain = Promise.resolve();

    // if (flag === false && Object.keys(metaDataInfo)?.length !== 0) {
    //   promiseChain = promiseChain.then(() =>
    //     getMetadataVerson(metaDataInfo.metadata_id)
    //   );
    // }
    if (flag === false && metaDataInfo?.metadata_id !== undefined) {
      setDetailDrawerVisible(true);
    }
    if (flag === false) {
      promiseChain = promiseChain
        .then(() => projectApi.getMetadataTag({ metadata_ver_id: id }))
        .then((res) => {
          if (res.code === 200) {
            setChooseList(res.data[id]);
          } else {
            setChooseList([]);
          }
        });
    }
    if (code === "WEB") {
      promiseChain = promiseChain.then(() =>
        projectApi.getMetadataWeb({ metadata_ver_id: id })
      );
    } else if (code === "WECHAT_ACC") {
      promiseChain = promiseChain.then(() =>
        projectApi.getMetadataWeChat({ metadata_ver_id: id })
      );
    } else if (code === "API") {
      promiseChain = promiseChain.then(() =>
        projectApi.getMetadataApi({ metadata_ver_id: id })
      );
    } else if (code === "DS") {
      promiseChain = promiseChain.then(() =>
        projectApi.getMetadataDb({ metadata_ver_id: id })
      );
    } else if (code === "TABLE") {
      promiseChain = promiseChain.then(() =>
        projectApi.getMetadataTable({ metadata_ver_id: id })
      );
    }

    promiseChain.then((res) => {
      if (res.code === 200) {
        flag
          ? layerHandle(false, res.data)
          : metadataDetail(res.data, id, metaDataInfo);
      } else {
        message.info("获取元数据详情失败");
      }
    });
  };

  // 录入or修改web
  const layerHandle = (flag, data) => {
    if (flag) {
      setAddNew(true);
      setMetaDrawerLoading(false);
      setDrawerTitle("录入元数据");
    } else if (flag === false) {
      setAddNew(false);
      setDrawerTitle("编辑元数据");
      // 表数据详情（修改模型的时候选择）
      projectApi.getMetaDBTable(data.metadata_ver_id).then((res) => {
        if (res.code === 200) {
          setDefaultData({ ...data, tableInfo: res.data });
        } else {
          setDefaultData(data);
          setMetaDrawerLoading(false);
        }
      });
    }
    setDialogDrawerVisible(true);
  };

  // 确认录入
  const dialogDrawerSuccess = (
    data,
    name,
    chooseList,
    dbTableList,
    dbTableId
  ) => {
    const newMetaCons = data?.meta_cons?.reduce(
      (prev, cur) => [
        ...prev,
        {
          cons_type_code: cur.cons_type_code,
          rel_metadata_id: cur.rel_metadata_id,
        },
      ],
      []
    );
    const tagNameList = chooseList
      ?.filter(
        (item) =>
          item.key.toString().indexOf(".") > -1 &&
          item.value.toString().indexOf(".") > -1
      )
      ?.reduce((pre, cur) => [...pre, { tag_name: cur.label }], []);
    const tagIdList = chooseList
      ?.filter(
        (item) =>
          item.key.toString().indexOf(".") === -1 &&
          item.value.toString().indexOf(".") === -1
      )
      ?.reduce((pre, cur) => [...pre, { tag_id: cur.value }], []);

    if (name === "WEB") {
      projectApi
        .addMetadataWeb({
          meta_ver: {
            chi_name: data.chi_name,
            eng_name: data.eng_name,
            metadata_desc: data.metadata_desc,
          },
          meta_web: {
            data_layer_id: data.data_layer_id,
            sub_id: data.sub_id,
            url: data.url,
          },
          meta_tag: [...tagNameList, ...tagIdList],
          meta_cons: newMetaCons,
        })
        .then((res) => {
          if (res.code === 200) {
            setDialogDrawerVisible(false);
            getMetadataList();
            message.success(res.message);
          } else {
            message.error(res.message);
          }
        });
    } else if (name === "WECHAT_ACC") {
      projectApi
        .addMetadataWeChat({
          meta_ver: {
            chi_name: data.chi_name,
            eng_name: data.eng_name,
            metadata_desc: data.metadata_desc,
          },
          meta_wechat_acc: {
            data_layer_id: data.data_layer_id,
            sub_id: data.sub_id,
            wechat_name: data.wechat_name,
          },
          meta_tag: [...tagNameList, ...tagIdList],
          meta_cons: newMetaCons,
        })
        .then((res) => {
          if (res.code === 200) {
            setDialogDrawerVisible(false);
            getMetadataList();
            message.success(res.message);
          } else {
            message.error(res.message);
          }
        });
    } else if (name === "API") {
      projectApi
        .addMetadataApi({
          meta_ver: {
            chi_name: data.chi_name,
            eng_name: data.eng_name,
            metadata_desc: data.metadata_desc,
          },
          meta_api: {
            data_layer_id: data.data_layer_id,
            sub_id: data.sub_id,
            api_url: data.api_url,
            req_method_code: data.req_method_code,
            content_type: data.content_type,
            req_parm_exp: data.req_parm_exp,
            resp_exp: data.resp_exp,
          },
          meta_tag: [...tagNameList, ...tagIdList],
          meta_cons: newMetaCons,
        })
        .then((res) => {
          if (res.code === 200) {
            setDialogDrawerVisible(false);
            getMetadataList();
            message.success(res.message);
          } else {
            message.error(res.message);
          }
        });
    } else if (name === "DS") {
      projectApi
        .addMetadataDb({
          meta_ver: {
            chi_name: data.chi_name,
            eng_name: data.eng_name,
            metadata_desc: data.metadata_desc,
          },
          meta_db: {
            db_server: data.db_server,
            db_database: data.db_database,
            db_username: data.db_username,
            db_password: data.db_password,
            db_port: data.db_port,
          },
          meta_tag: [...tagNameList, ...tagIdList],
          meta_db_type: {
            db_type_code: data.db_type_code,
          },
        })
        .then((res) => {
          if (res.code === 200) {
            setDialogDrawerVisible(false);
            getMetadataList();
            message.success(res.message);
          } else {
            message.error(res.message);
          }
        });
    } else if (name === "TABLE") {
      projectApi
        .addMetadataTable({
          meta_ver: {
            chi_name: data.chi_name,
            eng_name: data.eng_name,
            metadata_desc: data.metadata_desc,
          },
          meta_table: {
            table_info: {
              data_layer_id: data.data_layer_id,
              sub_id: data.sub_id,
              table_name: data.eng_name,
              table_cmnt: data.chi_name,
              db_type_code: data.db_type_code,
            },
            ...dbTableList,
            table_store: {
              ds_metadata_id: data.ds_metadata_id,
              ds_schema: data.ds_schema,
            },
          },
          meta_tag: [...tagNameList, ...tagIdList],
          meta_cons: newMetaCons,
        })
        .then((res) => {
          if (res.code === 200) {
            setDialogDrawerVisible(false);
            getMetadataList();
            message.success(res.message);
          } else {
            message.error(res.message);
          }
        });
    }
  };

  // 批量废弃
  const batchDelete = (value) => {
    MessageModal({
      type: "warning",
      description: "确认要废弃元数据吗？",
      title: "废弃元数据",
      okText: "废弃",
      onOk: () => {
        projectApi.abandonMetadata(value).then((res) => {
          if (res.code === 200) {
            message.success(res.message);
            getMetadataList();
          } else {
            message.error(res.message);
          }
        });
      },
    });
  };

  // 取消废弃
  const cancelDelete = (value) => {
    MessageModal({
      description: "确认要取消废弃元数据吗？",
      title: "取消废弃元数据",
      okText: "取消废弃",
      onOk: () => {
        projectApi.cannelAbandonMetadata(value).then((res) => {
          if (res.code === 200) {
            message.success(res.message);
            getMetadataList();
          } else {
            message.error(res.message);
          }
        });
      },
    });
  };

  // // 元数据详情信息
  // const metadataDetail = (data, id) => {
  //   projectApi
  //     .getBloodrRelation({
  //       metadata_ver_id: id,
  //     })
  //     .then((res) => {
  //       if (res.code === 200) {
  //         setRelationList(res.data);
  //       } else {
  //         setRelationList([]);
  //       }
  //     });
  //   // 表数据详情（修改模型的时候选择）
  //   projectApi.getMetaDBTable(data.metadata_ver_id).then((res) => {
  //     if (res.code === 200) {
  //       setMetadataDetailInfo({ ...data, tableInfo: res.data });
  //       setDetailDrawerLoading(false);
  //     } else {
  //       setMetadataDetailInfo(data);
  //       setDetailDrawerLoading(false);
  //     }
  //   });
  // };

  // 元数据详情信息
  const metadataDetail = (data, id, metaDataInfo) => {
    Promise.all([
      projectApi.getBloodrRelation({ metadata_ver_id: id }),
      projectApi.getMetaDBTable(data.metadata_ver_id),
    ])
      .then(([relationRes, tableRes]) => {
        if (relationRes.code === 200) {
          setRelationList(relationRes.data);
        } else {
          setRelationList([]);
        }

        if (tableRes.code === 200) {
          setMetadataDetailInfo({
            ...data,
            metadata_type_code: metaDataInfo?.metadata_type_code,
            tableInfo: tableRes.data,
          });
        } else {
          setMetadataDetailInfo(data);
        }

        setDetailDrawerLoading(false);
      })
      .catch((error) => {
        // 处理错误情况
        console.error("Error fetching metadata detail:", error);
        setDetailDrawerLoading(false);
      });
  };

  // useEffect(() => {
  //   if (DetailDrawerVisible) getMetadataVerson(DetailDrawerId);
  // }, [DetailDrawerVisible]);

  // 导入元数据
  const uploadFileSuccess = (id, files) => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("file", file?.originFileObj);
    });
    projectApi.importMetaData(id, formData).then((res) => {
      if (res.code === 200) {
        setFileList([]);
        setImportDialogVisible(false);
        message.success(
          `成功${res.data.success_num}个，失败${res.data.error_num}个，忽略${res.data.skip_num}个`
        );
        setImportFile(false);
        getMetadataList();
      } else {
        message.error(res.message);
      }
    });
  };

  //获取血缘关系
  const getBloodrRelation = (id, metaDataInfo) => {
    setRelationLoading(true);
    setMetaDataInfo(metaDataInfo);
    projectApi
      .getBloodrRelation({
        metadata_ver_id: id,
      })
      .then((res) => {
        if (res.code === 200) {
          setRelationList(res.data);
          if (res.data.length !== 0) {
            setRelationFlag(true);
          } else {
            setRelationFlag(false);
            message.info("暂无血缘关系");
          }
        } else {
          setRelationList([]);
        }
      })
      .finally(() => {
        setRelationLoading(false);
      });
  };

  return (
    <div className="MetaDataContent">
      {relationFlag ? (
        <BloodRelation
          setRelationFlag={setRelationFlag}
          setDetailDrawerVisible={setDetailDrawerVisible}
          RelationList={relationList}
          getmetaDataInfo={getmetaDataInfo}
          metaDataInfo={metaDataInfo}
          loading={relationLoading}
        ></BloodRelation>
      ) : (
        <>
          {/* 检索区域 */}
          <div className="topBox">
            {topLoading ? (
              <MySpin style={{ height: "100px" }}></MySpin>
            ) : (
              <>
                <Scrollbars
                  autoHide={true}
                  autoHideTimeout={5000}
                  style={{ height: "100%" }}
                >
                  <div className="searchItem">
                    <div className="label">元数据类型 :</div>
                    <div className="optionsBox">
                      {metaDataType?.map((item, index) => (
                        <div
                          key={index}
                          className="optionItem"
                          onClick={() => {
                            setMetaDataCode(item);
                            setLayerCode("all");
                            setSubCode("all");
                            setSubList([]);
                            setSubChildList([]);
                            setDBTypeCode("all");
                            setStatuCode("NORMAL");
                            setSubChildCode("all");
                            setMetadataName("");
                            setTagName("");
                            setNewMetadataName("");
                            setNewTagName("");
                            setPageNumber(1);
                          }}
                          style={{
                            backgroundColor:
                              item === metaDataCode ? "#006AB2" : null,
                            color: item === metaDataCode ? "#fff" : null,
                          }}
                        >
                          {dicCodeInfoList?.DG_METADATA_TYPE?.map(
                            (value) => value[item]
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                  {metaDataCode === "DS" ? (
                    <>
                      <div className="searchItem">
                        <div className="label">存储类型 :</div>
                        <div className="optionsBox">
                          {DBType?.map((item, index) => (
                            <div
                              key={index}
                              className="optionItem"
                              onClick={() => {
                                setDBTypeCode(item);
                                setPageNumber(1);
                              }}
                              style={{
                                backgroundColor:
                                  item === DBTypeCode ? "#006AB2" : null,
                                color: item === DBTypeCode ? "#fff" : null,
                              }}
                            >
                              {newDBType?.map((value) => value[item])}
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="searchItem">
                        <div className="label">状态 :</div>
                        <div className="optionsBox">
                          {statuDataType?.map((item, index) => (
                            <div
                              key={index}
                              className="optionItem"
                              onClick={() => {
                                setStatuCode(item);
                                setPageNumber(1);
                              }}
                              style={{
                                backgroundColor:
                                  item === statuCode ? "#006AB2" : null,
                                color: item === statuCode ? "#fff" : null,
                              }}
                            >
                              {dicCodeInfoList?.DG_METADATA_STAT?.map(
                                (value) => value[item]
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="searchItem">
                        <div className="label">元数据名称 :</div>
                        <div className="optionsBox">
                          <Input
                            bordered={false}
                            allowClear
                            style={{
                              borderBottom: "1px solid #999999",
                              width: "280px",
                            }}
                            value={metadataName}
                            onChange={(e) => {
                              setMetadataName(e.target.value);
                              if (e.target.value === "") setNewMetadataName("");
                            }}
                            onBlur={(e) => {
                              setNewMetadataName(e.target.value);
                              setPageNumber(1);
                            }}
                          />
                        </div>
                      </div>
                      <div className="searchItem">
                        <div className="label">标签 :</div>
                        <div className="optionsBox">
                          <Input
                            bordered={false}
                            allowClear
                            style={{
                              borderBottom: "1px solid #999999",
                              width: "280px",
                            }}
                            value={tagName}
                            onChange={(e) => {
                              setTagName(e.target.value);
                              if (e.target.value === "") setNewTagName("");
                            }}
                            onBlur={(e) => {
                              setNewTagName(e.target.value);
                              setPageNumber(1);
                            }}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="searchItem">
                        <div className="label">数据分层 :</div>
                        <div className="optionsBox">
                          {layerList?.map((item, index) => (
                            <div
                              key={index}
                              className="optionItem"
                              onClick={() => {
                                setSubCode("all");
                                setSubChildCode("all");
                                setLayerCode(item.data_layer_id);
                                setSubName(item.data_layer_name);
                                setPageNumber(1);
                                if (item.data_layer_id === "all") {
                                  setSubList([]);
                                }
                              }}
                              style={{
                                backgroundColor:
                                  item.data_layer_id === layerCode
                                    ? "#006AB2"
                                    : null,
                                color:
                                  item.data_layer_id === layerCode
                                    ? "#fff"
                                    : null,
                              }}
                            >
                              {item.data_layer_name}
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="searchItem">
                        <div className="label">主题域 :</div>
                        <div className="optionsBox" ref={listHeight}>
                          {layerCode !== ""
                            ? subList?.map((item, index) => (
                                <div
                                  key={index}
                                  className="optionItem"
                                  onClick={() => {
                                    setSubChildCode("all");
                                    setSubCode(item.sub_id);
                                    setPageNumber(1);
                                    if (item.sub_id === "all") {
                                      setSubChildList([]);
                                    }
                                  }}
                                  style={{
                                    backgroundColor:
                                      item.sub_id === subCode
                                        ? "#006AB2"
                                        : null,
                                    color:
                                      item.sub_id === subCode ? "#fff" : null,
                                  }}
                                >
                                  {item.sub_name}
                                </div>
                              ))
                            : null}
                        </div>
                      </div>
                      <div className="searchItem">
                        <div className="label">子主题域 :</div>
                        <div className="optionsBox">
                          {layerCode !== "" && subCode !== ""
                            ? subChildList?.map((item, index) => (
                                <div
                                  key={index}
                                  className="optionItem"
                                  onClick={() => {
                                    setSubChildCode(item.sub_id);
                                    setPageNumber(1);
                                  }}
                                  style={{
                                    backgroundColor:
                                      item.sub_id === subChildCode
                                        ? "#006AB2"
                                        : null,
                                    color:
                                      item.sub_id === subChildCode
                                        ? "#fff"
                                        : null,
                                  }}
                                >
                                  {item.sub_name}
                                </div>
                              ))
                            : null}
                        </div>
                      </div>
                      <div className="searchItem">
                        <div className="label">状态 :</div>
                        <div className="optionsBox">
                          {statuDataType?.map((item, index) => (
                            <div
                              key={index}
                              className="optionItem"
                              onClick={() => {
                                setStatuCode(item);
                                setPageNumber(1);
                              }}
                              style={{
                                backgroundColor:
                                  item === statuCode ? "#006AB2" : null,
                                color: item === statuCode ? "#fff" : null,
                              }}
                            >
                              {dicCodeInfoList?.DG_METADATA_STAT?.map(
                                (value) => value[item]
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </>
                  )}

                  {metaDataCode !== "DS" ? (
                    <>
                      <div className="searchItem">
                        <div className="label">元数据名称 :</div>
                        <div className="optionsBox">
                          <Input
                            bordered={false}
                            allowClear
                            style={{
                              borderBottom: "1px solid #999999",
                              width: "280px",
                            }}
                            value={metadataName}
                            onChange={(e) => {
                              setMetadataName(e.target.value);
                              if (e.target.value === "") {
                                setNewMetadataName("");
                                setPageNumber(1);
                              }
                            }}
                            onBlur={(e) => {
                              setNewMetadataName(e.target.value);
                              setPageNumber(1);
                            }}
                            onPressEnter={(e) => {
                              setNewMetadataName(e.target.value);
                              setPageNumber(1);
                            }}
                          />
                        </div>
                      </div>

                      <div className="searchItem">
                        <div className="label">标签 :</div>
                        <div className="optionsBox">
                          <Input
                            bordered={false}
                            allowClear
                            style={{
                              borderBottom: "1px solid #999999",
                              width: "280px",
                            }}
                            value={tagName}
                            onChange={(e) => {
                              setTagName(e.target.value);
                              if (e.target.value === "") {
                                setNewTagName("");
                                setPageNumber(1);
                              }
                            }}
                            onBlur={(e) => {
                              setNewTagName(e.target.value);
                              setPageNumber(1);
                            }}
                            onPressEnter={(e) => {
                              setNewTagName(e.target.value);
                              setPageNumber(1);
                            }}
                          />
                        </div>
                      </div>
                    </>
                  ) : null}
                </Scrollbars>
              </>
            )}
          </div>
          {/* 排序 */}
          <div className="filterItem">
            <div className="optionBoxs">
              <Checkbox
                indeterminate={indeterminate}
                onChange={onCheckAllChange}
                checked={checkAll}
                disabled={statuCode !== "NORMAL"}
              >
                全选
              </Checkbox>
              {orderDataList.map((item) => (
                <div key={item.key} className="optionItem">
                  {item.label}
                  <div className="iconBox">
                    <CaretUpOutlined
                      className="upIcon"
                      onClick={() => {
                        sortOrder(item, "asc");
                        setUpOrderCode(item);
                        setDownOrderCode({});
                      }}
                      style={{ color: item === upOrderCode ? "#0068B2" : null }}
                    />
                    <CaretDownOutlined
                      className="downIcon"
                      onClick={() => {
                        sortOrder(item, "desc");
                        setDownOrderCode(item);
                        setUpOrderCode({});
                      }}
                      style={{
                        color: item === downOrderCode ? "#0068B2" : null,
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>

            {/* 按钮区域 */}
            <div className="buttonBox">
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  layerHandle(true);
                  setNewDBTypeCode(null);
                }}
              >
                录入
              </Button>
              <Button
                type="primary"
                icon={<DiffOutlined />}
                onClick={() => {
                  if (importFile) {
                    message.info("正在导入中，请勿重复操作!");
                  } else {
                    setOkText("导入");
                    setOnTitle("导入元数据");
                    setImportDialogVisible(true);
                  }
                }}
              >
                导入
              </Button>
              <Dropdown
                overlay={menu}
                trigger={["click"]}
                disabled={statuCode !== "NORMAL"}
              >
                <Button type="primary">
                  批量操作
                  <DownOutlined />
                </Button>
              </Dropdown>
            </div>
          </div>
          {/* 展示 */}
          <div className="cardBox">
            <Scrollbars
              autoHide={true}
              autoHideTimeout={5000}
              style={{ height: "100%", width: "100%" }}
            >
              <CheckboxGroup
                onChange={checkChange}
                value={checkedList}
                style={{ width: "100%" }}
              >
                {loading ? (
                  <MySpin></MySpin>
                ) : metaList.length > 0 ? (
                  metaList.map((item, index) => (
                    <MetaDataCard
                      metaDataInfo={item}
                      key={index}
                      plainOptionList={plainOptionList}
                      metaDataCode={metaDataCode}
                      cancelDelete={cancelDelete}
                      batchDelete={batchDelete}
                      layerHandle={layerHandle}
                      metadataDetail={metadataDetail}
                      checkedList={checkedList}
                      getmetaDataInfo={getmetaDataInfo}
                      setCopied={setCopied}
                      setCopiedFlag={setCopiedFlag}
                      setNewDBTypeCode={setNewDBTypeCode}
                      // setDetailDrawerVisible={setDetailDrawerVisible}
                      getBloodrRelation={getBloodrRelation}
                    ></MetaDataCard>
                  ))
                ) : (
                  <div className="noData">
                    <Empty></Empty>
                  </div>
                )}
              </CheckboxGroup>
            </Scrollbars>
          </div>
          {loading ? null : total >= 1 ? (
            <div className="paginationBox">
              <Pagination
                onChange={pageChange}
                className="myPagination"
                pageSize={pageSize}
                current={pageNumber}
                total={total}
                showTotal={(total) => `共 ${total} 条`}
                showSizeChanger={true}
                showQuickJumper
                pageSizeOptions={[10, 20, 50, 100]}
              />
            </div>
          ) : null}
        </>
      )}
      <DialogDrawer
        okText="录入"
        cancelText="取消"
        drawerTitle={drawerTitle}
        layerList={dialoglayerList}
        metaDataType={metaDataType}
        dicCodeInfoList={dicCodeInfoList}
        defaultData={defaultData}
        addNew={addNew}
        setAddNew={setAddNew}
        onOk={dialogDrawerSuccess}
        visible={DialogDrawerVisible}
        onCancel={() => {
          setDialogDrawerVisible(false);
        }}
        metaDataCode={metaDataCode}
        subId={subId}
        setDialogDrawerVisible={setDialogDrawerVisible}
        getMetadataList={getMetadataList}
        tagList={tagList}
        MetadataStorageList={MetadataStorageList}
        loading={metaDrawerLoading}
        setMetaDrawerLoading={setMetaDrawerLoading}
        setNewDBTypeCode={setNewDBTypeCode}
        newDBTypeCode={newDBTypeCode}
        copied={copied}
        setCopied={setCopied}
        setCopiedFlag={setCopiedFlag}
        copiedFlag={copiedFlag}
      ></DialogDrawer>
      <BatchDrawer
        okText={okText}
        onTitle={onTitle}
        cancelText="取消"
        visible={importDialogVisible}
        onCancel={() => {
          setImportDialogVisible(false);
        }}
        metaDataType={metaDataType}
        layerList={dialoglayerList}
        checkedList={checkedList}
        setImportDialogVisible={setImportDialogVisible}
        getMetadataList={getMetadataList}
        tagList={tagList}
        metaDataCode={metaDataCode}
        successFunc={uploadFileSuccess}
        fileList={fileList}
        setFileList={setFileList}
        setImportFile={setImportFile}
      ></BatchDrawer>
      <DetailDrawer
        metadataVersoList={metadataVersoList}
        visible={DetailDrawerVisible}
        defaultData={metadataDetailInfo}
        onCancel={() => {
          setDetailDrawerVisible(false);
        }}
        relationList={relationList}
        loading={DetailDrawerLoading}
        getmetaDataInfo={getmetaDataInfo}
        chooseList={chooseList}
        metaDataCode={metaDataCode}
        MetadataStorageList={MetadataStorageList}
      ></DetailDrawer>
    </div>
  );
};
export default MetaData;
