import "./index.scss";
import { Checkbox, Badge, Tag, Tooltip, message } from "antd";
import { useEffect, useState } from "react";
import {
  DeleteOutlined,
  FormOutlined,
  RollbackOutlined,
  CopyOutlined,
  ClusterOutlined,
} from "@ant-design/icons";
import APIIcon from "../../../../../assets/API.svg";
import WeChatIcon from "../../../../../assets/WeChat.svg";
import DSIcon from "../../../../../assets/数据存储.svg";
import webIcon from "../../../../../assets/网站.svg";
import taleIcon from "../../../../../assets/表.svg";
import { useSelector } from "react-redux";
import projectApi from "../../../../../apis/project";
import BloodRelation from "../BloodRelation";

const MetaDataCard = (props) => {
  const {
    metaDataInfo, // 元数据信息
    metaDataCode,
    cancelDelete, // 取消废弃元数据
    batchDelete, // 废弃元数据
    getmetaDataInfo,
    setCopied,
    setCopiedFlag,
    layerHandle,
    setNewDBTypeCode,
    setDetailDrawerVisible,
    getBloodrRelation,
  } = props;
  const [RelationList, setRelationList] = useState([]);
  const dicCodeInfoList = useSelector((state) => state.Project.dicCodeInfoList);
  // 数据分层主题域映射
  const layerSubList = useSelector((state) => state.Project.layerSubList);
  // 元数据类型
  const metaDataType = dicCodeInfoList?.DG_METADATA_TYPE;
  // 获取数据存储详细信息
  const getMetadataDb = (id) => {
    projectApi.getMetadataDb({ metadata_ver_id: id }).then((res) => {
      if (res.code === 200) {
        setCopied(res.data);
        message.info("复制成功");
        layerHandle(true);
        setNewDBTypeCode(null);
      } else {
        message.info("复制失败");
      }
    });
  };

  // img src切换
  const changeImage = (value) => {
    if (value === "DS") {
      return DSIcon;
    } else if (value === "WEB") {
      return webIcon;
    } else if (value === "API") {
      return APIIcon;
    } else if (value === "TABLE") {
      return taleIcon;
    } else if (value === "WECHAT_ACC") {
      return WeChatIcon;
    }
  };
  // //获取血缘关系
  // const getBloodrRelation = (id) => {
  //   projectApi
  //     .getBloodrRelation({
  //       metadata_ver_id: id,
  //     })
  //     .then((res) => {
  //       if (res.code === 200) {
  //         // setRelationList(res.data);
  //         if (res.data.length !== 0) {
  //           BloodRelation({
  //             title: "血缘关系",
  //             RelationList: res.data,
  //             metaDataInfo,
  //             metaDataType,
  //             getmetaDataInfo,
  //             setDetailDrawerVisible,
  //             layerSubList,
  //           });
  //         } else {
  //           message.info("暂无血缘关系");
  //         }
  //       } else {
  //         // setRelationList([]);
  //       }
  //     });
  // };
  return (
    <>
      <div className="cardItem">
        <div className="leftItem">
          <Checkbox
            value={metaDataInfo}
            disabled={metaDataInfo?.metadata_stat_code !== "NORMAL"}
          ></Checkbox>
        </div>
        <div className="imgItem">
          <img src={changeImage(metaDataCode)} className="infoImg"></img>
        </div>
        <div className="rightItem">
          <div
            className="title"
            onClick={() => {
              getmetaDataInfo(
                false,
                metaDataInfo?.metadata_ver_id,
                metaDataInfo,
                metaDataInfo?.metadata_type_code
              );
            }}
          >
            <span>{`${metaDataInfo?.chi_name} / ${metaDataInfo?.eng_name}`}</span>
          </div>
          <div className="webContent">
            {metaDataInfo?.metadata_stat_code === "NORMAL" ? (
              <>
                <Badge color="#61D4AD" />
                正常
              </>
            ) : (
              <>
                <Badge color="#FE545F" />
                废弃
              </>
            )}
            {/* <span className="web">
              {metaDataInfo?.url || metaDataInfo?.api_url}
            </span> */}
          </div>
          <div className="detailContent">
            <span className="label">元数据类型</span>
            <span className="value" style={{ width: "60px" }}>
              {metaDataInfo?.metadata_type_code_value}
            </span>
            {metaDataInfo?.db_type_code !== undefined ? (
              <>
                <span className="label">存储类型</span>
                <span className="value" style={{ width: "110px" }}>
                  {metaDataInfo?.db_type_code}
                </span>
              </>
            ) : null}
            {metaDataCode !== "DS" &&
            Object.values(layerSubList?.DATA_LAYER_RLS).find(
              (item) => item?.data_layer_id === metaDataInfo?.data_layer_id
            ) !== undefined ? (
              <>
                <span className="label">数据分层</span>
                <span className="value" style={{ width: "100px" }}>
                  {Object.values(metaDataInfo).length !== 0
                    ? Object.values(layerSubList?.DATA_LAYER_RLS).find(
                        (item) =>
                          item?.data_layer_id === metaDataInfo?.data_layer_id
                      )?.data_layer_name
                    : null}
                </span>

                <span className="label">主题域</span>
                <Tooltip
                  title={
                    Object.values(metaDataInfo).length !== 0
                      ? Object.values(layerSubList?.SUB_RLS).find(
                          (item) => item?.sub_id === metaDataInfo?.sub_id
                        )?.sub_name
                      : null
                  }
                  overlayClassName="sharesTip"
                >
                  <span className="value" style={{ marginLeft: "-15px" }}>
                    {Object.values(metaDataInfo).length !== 0
                      ? Object.values(layerSubList?.SUB_RLS).find(
                          (item) => item?.sub_id === metaDataInfo?.sub_id
                        )?.sub_name
                      : null}
                  </span>
                </Tooltip>
              </>
            ) : null}

            <span className="label">当前版本</span>
            <span className="value" style={{ width: "60px" }}>
              {metaDataInfo?.ver_no}
            </span>
            <span className="label">创建人</span>
            <span
              className="value"
              style={{ width: "85px", marginLeft: "-15px" }}
            >
              {metaDataInfo?.created_by_id_value}
            </span>
            <span className="label">创建时间</span>
            <span className="value">{metaDataInfo?.created_time}</span>
            <span className="label">更新时间</span>
            <span className="value">{metaDataInfo?.updated_time}</span>
          </div>

          <div className="tagItem">
            {metaDataInfo?.tag_list.map((item, index) => (
              <Tag key={index}>{item?.tag_name}</Tag>
            ))}
          </div>
        </div>
        <div className="iconItem">
          <Tooltip placement="top" title="血缘关系" arrowPointAtCenter>
            <ClusterOutlined
              style={{ marginRight: "8px" }}
              onClick={() =>
                getBloodrRelation(metaDataInfo?.metadata_ver_id, metaDataInfo)
              }
            />
          </Tooltip>

          {metaDataInfo?.metadata_type_code === "DS" ? (
            <>
              <Tooltip placement="top" title="一键复制" arrowPointAtCenter>
                <CopyOutlined
                  style={{ marginRight: "8px" }}
                  onClick={() => {
                    getMetadataDb(metaDataInfo?.metadata_ver_id);
                    setCopiedFlag(true);
                  }}
                />
              </Tooltip>
            </>
          ) : null}
          {metaDataInfo?.metadata_stat_code === "NORMAL" ? (
            <>
              <Tooltip placement="top" title="修改" arrowPointAtCenter>
                <FormOutlined
                  onClick={() => {
                    getmetaDataInfo(
                      true,
                      metaDataInfo?.metadata_ver_id,
                      metaDataInfo,
                      metaDataInfo?.metadata_type_code
                    );
                  }}
                />
              </Tooltip>
              <Tooltip placement="top" title="废弃" arrowPointAtCenter>
                <DeleteOutlined
                  style={{ marginLeft: "8px" }}
                  onClick={() => batchDelete([metaDataInfo?.metadata_id])}
                />
              </Tooltip>
            </>
          ) : (
            <Tooltip placement="top" title="撤销废弃" arrowPointAtCenter>
              <RollbackOutlined
                onClick={() => cancelDelete([metaDataInfo?.metadata_id])}
              />
            </Tooltip>
          )}
        </div>
      </div>
    </>
  );
};
export default MetaDataCard;
