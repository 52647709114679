import { useState, useEffect } from "react";
import { Layout, Select, Popconfirm, message, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "./index.scss";
import logo from "../../../../assets/logo-g.png";
import logos from "../../../../assets/logo.png";
import { debounce } from "../../../../unit/unit";
import projectApi from "../../../../apis/project";
import NoticeDrawer from "../../../../components/NoticeDrawer";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { getToken, removeToken, setRedirectUrl } from "../../../../unit/index";
import UserApi from "../../../../apis/user/index";

const { Header } = Layout;

export function MyHeader(props) {
  const token = getToken();
  const location = useLocation();
  const { menuList, apiName, setApiName, setSelectName, selectName } = props;

  const [visible, setVisible] = useState(false);
  const [userOptions, setUserOptions] = useState([]); // 搜索下拉框选项
  const Navigate = useNavigate();
  const { Option } = Select;

  const queryParams = new URLSearchParams(location.search);
  const showName = queryParams.get("show_name");

  // 获取用户下拉框选项
  const searchUser = (keyword) => {
    fetchApiData(keyword);

    // const isAlphaNumeric = /^[a-z0-9]+$/.test(keyword); // 检查是否只包含小写字母或数字
    // const info = menuList.filter((item) => {
    //   if (isAlphaNumeric && item.api_id) {
    //     return (
    //       item.api_id.includes(keyword) || item.menu_name.includes(keyword)
    //     );
    //   } else {
    //     return (
    //       item.menu_name.includes(keyword) || item.api_id.includes(keyword)
    //     );
    //   }
    // });

    // setUserOptions(info);
  };
  const fetchApiData = async (value) => {
    try {
      const res = await projectApi.getAllApiList({ key_word: value });

      if (res.code === 200) {
        const result = res.data?.flatMap(
          (item) =>
            item.api_list?.map((child) => ({
              menu_name: child.chi_name,
              api_id: child.api_id.trim(),
            })) || []
        );
        // console.log(result);
        setUserOptions(result);
      }
    } catch (error) {
      console.error("Error fetching API list:", error);
    }
  };
  useEffect(() => {
    setUserOptions(menuList);
  }, [menuList]);

  useEffect(() => {
    if (!apiName) {
      setUserOptions(menuList);
    }
  }, [apiName]);

  return (
    <div className="headerContainer">
      <Header>
        <div className="headerLeft">
          <div className="logo">
            {<img src={logos} className="logoImg" alt="" />}
          </div>
          <div className="left-title">技术文档</div>
          <div
            className="left-value
          "
          >
            {/* <Select
              allowClear
              showSearch
              suffixIcon={<SearchOutlined style={{ fontSize: "14px" }} />}
              onSearch={debounce((e) => {
                setSelectName(e);
                if (e !== "") {
                  searchUser(e);
                } else {
                  setUserOptions(menuList);
                }
              }, 1000)}
              style={{ width: "248px" }}
              placeholder="请输入查询接口名称/接口ID"
              filterOption={false}
              value={apiName}
              onChange={(e) => setApiName(e)}
              onClear={(e) => {
                setUserOptions(menuList);
                setSelectName("");
              }}
            >
              {userOptions?.map((data) => (
                <Option key={data.api_id} value={data.menu_name}>
                  {data.menu_name}
                </Option>
              ))}
            </Select> */}
            <Input
              allowClear
              suffix={<SearchOutlined style={{ fontSize: "14px" }} />}
              style={{ width: "248px" }}
              placeholder="请输入查询接口名称/接口ID/字段"
              // value={selectName}
              // onChange={(e) => {
              //   setSelectName(e.target.value);
              // }}
              onChange={debounce((e) => setSelectName(e.target.value), 1000)}
              onClear={() => {
                setSelectName("");
              }}
            />
          </div>
        </div>
        <>
          <div className="ant-header-right">
            <img src={logo} alt="" />

            {!token ? (
              <a onClick={() => Navigate("/login")}>登录</a>
            ) : (
              <span className="sys-pepo">{showName}</span>
            )}
          </div>
        </>
      </Header>

      <NoticeDrawer
        visible={visible}
        onClose={() => setVisible(false)}
      ></NoticeDrawer>
    </div>
  );
}

export default MyHeader;
