import { createSlice } from "@reduxjs/toolkit";
const ProjectInfo = createSlice({
  name: "project",
  initialState: {
    projectInfo: {},
    projectProgressCodeList: [],
    projectProgressCode: null,
    menuList: [],
    dicCodeInfoList: {},
    layerSubList: {},
    BloodRelation: [],
    newselectName: "",
  },
  reducers: {
    setProjectInfo: (state, action) => {
      state.projectInfo = action.payload;
    },
    setProjectProgressCodeList: (state, action) => {
      state.projectProgressCodeList = action.payload;
    },
    setProjectProgressCode: (state, action) => {
      state.projectProgressCode = action.payload;
    },
    setMenusList: (state, action) => {
      state.menuList = action.payload;
    },
    setDicCodeInfoList: (state, action) => {
      state.dicCodeInfoList = action.payload;
    },
    setLayerSubList: (state, action) => {
      state.layerSubList = action.payload;
    },
    setNewselectName: (state, action) => {
      state.newselectName = action.payload;
    },
    setBloodRelation: (state, action) => {
      state.BloodRelation = action.payload;
    },
  },
});
export const {
  setProjectInfo,
  setProjectProgressCodeList,
  setProjectProgressCode,
  setMenusList,
  setDicCodeInfoList,
  setLayerSubList,
  setBloodRelation,
  setNewselectName,
} = ProjectInfo.actions;

export default ProjectInfo;
