import "./index.scss";
import { useEffect, useState, useRef } from "react";
import {
  Button,
  Select,
  Modal,
  Input,
  Form,
  Table,
  Pagination,
  Tooltip,
  ConfigProvider,
} from "antd";
import dbTAble from "../../../../../assets/表green.svg";
import LoadingSvg from "../../../../../assets/loading.svg";
import { InfoCircleFilled, CheckCircleFilled } from "@ant-design/icons";
import DropdownSvg from "../../../../../assets/下拉.svg";
import zhCN from "antd/lib/locale/zh_CN";
import wrapper from "./wrapper";
import { ReactSVG } from "react-svg";

import MyEmpty from "../../../../../components/Empty";
import projectApi from "../../../../../apis/project";

const { Option } = Select;

const AddModal = (props) => {
  const [visible, setVisible] = useState(true);
  const [form] = Form.useForm();
  const [orderList, setOrderList] = useState(["updated_time", "desc"]); // 筛选条件

  const {
    /* 标题 */
    title,
    /* 关闭触发的回调 */
    onCancel,
    /* 销毁组件回调(高阶组件自定义) */
    closeDialog,
    /* 确定触发的回调 */
    onOk,
    /* 类型 warning 和 默认 */
    type,
    /* 确认按钮文字 */
    okText,
    /* 取消按钮文字 */
    cancelText,
    /* 不要按钮 */
    noBtn,
    /* 点击蒙层是否允许关闭 */
    maskClosable,
    metaDataType, // 元数据类型
    dicCodeInfoList, // 用户字典
    layerList, // 数据分层
    metaDataCode,
    dbCode, // 数据存储metadata_id
    layerSubList, // 数据分层和主题域映射关系
    schema, // schema
    relationList,
  } = props;

  // 表格
  const refTable = useRef(null);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedRowList, setSelectedRowList] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [metaList, setMetaList] = useState([]); // 元数据查询结果列表
  const [queryList, setqueryList] = useState({}); // 筛选列表
  const [loading, setLoading] = useState(false);
  const [DBTableList, setDBTableList] = useState([]); // 从数据库中获取所有的表
  const [DBTableValue, setDBTableValue] = useState(""); // 从数据库中获取所有的表（筛选条件）
  const [chooseDBTableList, setChooseDBTableList] = useState([]); // 从数据库中获取所有的表（自定义筛选）
  const [rowId, setRowId] = useState(""); // 选中表
  const columns = [
    {
      title: "中文名称",
      dataIndex: "chi_name",
      sorter: true,
      render: (data, info) => {
        return (
          <Tooltip
            overlayClassName="headerTipContent"
            placement="bottomLeft"
            // trigger="click"
            title={
              <div className="headerTip">
                <div className="headerTipTitle">{`${info?.chi_name} / ${info?.eng_name}`}</div>
                <div className="headerTipText">
                  元数据类型: {info?.metadata_type_code_value}
                </div>
                <div className="headerTipText">
                  数据分层:
                  {info !== null
                    ? Object.values(layerSubList?.DATA_LAYER_RLS).find(
                        (item) => item?.data_layer_id === info?.data_layer_id
                      )?.data_layer_name
                    : null}
                </div>
                <div className="headerTipText">
                  主题域:
                  {info !== null
                    ? Object.values(layerSubList?.SUB_RLS).find(
                        (item) => item?.sub_id === info?.sub_id
                      )?.sub_name
                    : null}
                </div>
                {/* {info?.metadata_type_code === "TABLE" ? (
                  <>
                    <div className="headerTipText">
                      存储类型 {info?.db_type_code}
                    </div>
                  </>
                ) : null} */}
                <div className="headerTipText">
                  当前版本: {info?.cur_ver_no}
                </div>
              </div>
            }
          >
            <div className="titleValue">{info?.chi_name}</div>
          </Tooltip>
        );
      },
    },
    {
      title: "英文名称",
      dataIndex: "eng_name",
      sorter: true,
      render: (data, info) => {
        return (
          <Tooltip
            overlayClassName="headerTipContent"
            placement="bottomLeft"
            // trigger="click"
            title={
              <div className="headerTip">
                <div className="headerTipTitle">{`${info?.chi_name} / ${info?.eng_name}`}</div>
                <div className="headerTipText">
                  元数据类型: {info?.metadata_type_code_value}
                </div>
                <div className="headerTipText">
                  数据分层:
                  {info !== null
                    ? Object.values(layerSubList?.DATA_LAYER_RLS).find(
                        (item) => item?.data_layer_id === info?.data_layer_id
                      )?.data_layer_name
                    : null}
                </div>
                <div className="headerTipText">
                  主题域:
                  {info !== null
                    ? Object.values(layerSubList?.SUB_RLS).find(
                        (item) => item?.sub_id === info?.sub_id
                      )?.sub_name
                    : null}
                </div>
                {/* {info?.metadata_type_code === "TABLE" ? (
                  <>
                    <div className="headerTipText">
                      存储类型 {info?.db_type_code}
                    </div>
                  </>
                ) : null} */}
                <div className="headerTipText">
                  当前版本: {info?.cur_ver_no}
                </div>
              </div>
            }
          >
            <div className="titleValue">{info?.eng_name}</div>
          </Tooltip>
        );
      },
    },
    {
      title: "创建时间",
      dataIndex: "created_time",
      sorter: true,
      width: "12%",
      render: (_, record) => record?.created_time.split(" ")[0],
    },
    {
      title: "更新时间",
      dataIndex: "updated_time",
      sorter: true,
      width: "12%",
      render: (_, record) => record?.updated_time.split(" ")[0],
    },
  ];

  const tableColumns = [
    {
      dataIndex: "chi_name",
      render: (_, info) => {
        return (
          <div style={{ display: "flex" }}>
            <img src={dbTAble} style={{ marginRight: "8px" }}></img>
            <span>{info.chi_name}</span>
          </div>
        );
      },
    },
  ];

  const handleCancel = () => {
    onCancel && onCancel();
    setVisible(false);
    setTimeout(() => {
      closeDialog();
    }, 500);
  };

  const handleOk = () => {
    if (title === "添加来源" || title === "添加流向") {
      onOk && onOk(selectedRowList);
    } else {
      getMetaTableDesc();
    }

    setVisible(false);
    setTimeout(() => {
      closeDialog();
    }, 500);
  };

  // 颜色
  const getStyleOptions = () => {
    let color = "";
    let icon = null;
    let cor = "";
    switch (type) {
      case "warning":
        color = "#FE545F";
        icon = <InfoCircleFilled style={{ color: "#FE545F" }} />;
        cor = "#fff";
        break;
      default:
        color = "#0068B2";
        icon = <CheckCircleFilled style={{ color: "#0068B2" }} />;
        cor = "#fff";
        break;
    }
    return {
      color,
      icon,
      btnStyle: {
        background: color,
        borderColor: color,
        color: cor,
      },
    };
  };

  const StyleOptions = getStyleOptions();

  // 添加查询
  const demandFinish = (value) => {
    if (title === "选择表") setDBTableValue(value?.table_name);
    if (title === "添加来源" || title === "添加流向") {
      setqueryList(value);
      const data = value;

      if (data.metadata_type_name === "") {
        delete data.metadata_type_name;
      }
      getMetadataList(data);
    } else if (value.table_name !== undefined && value.table_name !== "") {
      setChooseDBTableList(
        (item) =>
          (item = DBTableList?.filter(
            (data) => data.chi_name.includes(value.table_name) === true
          ))
      );
    }
  };

  // 元数据查询
  const getMetadataList = (value) => {
    // 元数据查询
    let params = {
      metadata_stat_code: "NORMAL",
      page: pageNumber,
      size: pageSize,
      order_list: orderList.toString(),
    };
    projectApi.getMetadataList({ ...params, ...value }).then((res) => {
      if (res.code === 200) {
        setTotal(res.data.total);
        setMetaList(res.data.items);
        setLoading(false);
      } else {
        setMetaList([]);
        setLoading(false);
        setTotal(0);
      }
    });
  };

  // 表格第一项多选
  const onSelectChange = (selectedRowKeys, selectedRows) => {
    // console.log(metadata_id);
    setSelectedRowKeys(selectedRowKeys);
    setSelectedRowList(selectedRows);
  };

  // 血缘关系
  const rowSelection = {
    selectedRowKeys,
    // onChange: onSelectChange,
    onSelect: (record, selected) => {
      if (selected) {
        setSelectedRowKeys((before) => {
          return [...before, record.metadata_id];
        });
        setSelectedRowList((before) => {
          return [...before, record];
        });
      } else {
        setSelectedRowKeys((before) => {
          return before?.filter((el) => el !== record.metadata_id);
        });
        setSelectedRowList((before) => {
          return before?.filter((el) => el.metadata_id !== record.metadata_id);
        });
      }
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      if (selected) {
        setSelectedRowKeys((before) => {
          const ids = changeRows?.map((el) => el.metadata_id);
          return [...before, ...ids];
        });
        setSelectedRowList((before) => {
          return [...before, ...changeRows];
        });
      } else {
        const ids = changeRows?.map((el) => el.metadata_id);
        setSelectedRowKeys((before) => {
          return before?.filter((el) => ids.indexOf(el) === -1);
        });
        setSelectedRowList((before) => {
          return before?.filter((el) => ids.indexOf(el.metadata_id) === -1);
        });
      }
    },
  };

  // 分页
  const onChange = (page, pageSize) => {
    setPageSize(pageSize);
    setPageNumber(page);
  };
  // 改变表格排序
  const tableChange = (pagination, filters, sorter, extra) => {
    if (sorter.order !== undefined) {
      setOrderList([sorter.field, sorter.order === "descend" ? "desc" : "asc"]);
    }
  };

  useEffect(() => {
    if (Object.keys(queryList).length !== 0) getMetadataList(queryList);
  }, [pageSize, pageNumber, orderList]);

  // 从数据库中选择表
  const getMetaTableDesc = () => {
    let data = { ds_metadata_id: dbCode, table_name: rowId };
    if (schema !== undefined) {
      data.schema = schema;
    }
    projectApi.getMetaTableDesc(data).then((res) => {
      if (res.code === 200) {
        onOk && onOk(res.data, rowId);
      } else {
        onOk && onOk({});
      }
    });
  };

  // 根据数据库查询所有的表
  useEffect(() => {
    if (title === "选择表") {
      setLoading(true);
      let data = { metadata_id: dbCode };
      if (schema !== undefined) {
        data.schema = schema;
      }
      projectApi.getMetadataDBTableList(data).then((res) => {
        if (res.code === 200) {
          if (res.data !== null) {
            const data = res.data?.reduce(
              (cur, pre) => [...cur, { chi_name: pre }],
              []
            );
            setDBTableList(data);
          }

          setLoading(false);
        } else {
          setDBTableList([]);
          setLoading(false);
        }
      });
    }
  }, [dbCode]);

  // 血缘关系数据回显
  useEffect(() => {
    const ids = relationList?.map((item) => item.metadata_id);
    setSelectedRowKeys(ids);
  }, [relationList]);

  return (
    <ConfigProvider locale={zhCN}>
      <Modal
        title={title}
        visible={visible}
        onCancel={handleCancel}
        wrapClassName="AddModal"
        centered
        footer={null}
        maskClosable={maskClosable}
        width={984}
      >
        <div className="addModalContent">
          <Form
            form={form}
            autoComplete="off"
            className="modalForm"
            preserve={false}
            onFinish={demandFinish}
          >
            {title === "添加来源" || title === "添加流向" ? (
              <>
                <Form.Item
                  label="元数据类型"
                  name="metadata_type_code"
                  initialValue={metaDataCode}
                >
                  <Select
                    placeholder="请选择"
                    allowClear
                    showSearch={true}
                    suffixIcon={
                      <ReactSVG
                        src={DropdownSvg}
                        style={{ width: "16px", height: "16px" }}
                      ></ReactSVG>
                    }
                    style={{ width: "150px" }}
                  >
                    {metaDataType?.map((item) => {
                      return (
                        <Option value={item} key={item}>
                          {dicCodeInfoList?.DG_METADATA_TYPE?.map(
                            (value) => value[item]
                          )}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item label="数据分层" name="data_layer_id">
                  <Select
                    placeholder="全部"
                    showSearch
                    allowClear
                    showArrow="true"
                    suffixIcon={
                      <ReactSVG
                        src={DropdownSvg}
                        style={{ width: "16px", height: "16px" }}
                      ></ReactSVG>
                    }
                    style={{ width: "150px" }}
                  >
                    {layerList?.map((item) => {
                      return (
                        <Option
                          value={item?.data_layer_id}
                          key={item?.data_layer_id}
                        >
                          {item.data_layer_name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item label="元数据名称" name="metadata_type_name">
                  <Input
                    allowClear
                    style={{
                      width: "220px",
                    }}
                    onPressEnter={() => {
                      form.submit();
                    }}
                  />
                </Form.Item>
              </>
            ) : (
              <Form.Item label="表名" name="table_name">
                <Input
                  bordered={false}
                  allowClear
                  style={{
                    width: "300px",
                    borderBottom: "1px solid #313131",
                  }}
                  onChange={(e) => {
                    if (e.target.value === "") setDBTableValue("");
                  }}
                />
              </Form.Item>
            )}
          </Form>
          <Button
            style={{
              background: "#0068B2",
              color: "#fff",
              width: "92px",
              marginTop: title !== "选择表" ? "-24px" : "-10px",
              marginRight: title !== "选择表" ? null : "450px",
            }}
            onClick={() => {
              form.submit();
            }}
          >
            查询
          </Button>

          <div
            className="tableContent"
            style={{ width: "100%" }}
            ref={refTable}
          >
            {title === "添加来源" || title === "添加流向" ? (
              <>
                <Table
                  className="myFormTable"
                  rowSelection={rowSelection}
                  columns={columns}
                  dataSource={metaList}
                  pagination={false}
                  rowKey={(record, id) => record?.metadata_id}
                  scroll={{
                    y: "500px",
                  }}
                  locale={{
                    emptyText: <MyEmpty></MyEmpty>,
                  }}
                  onChange={tableChange}
                  loading={
                    loading
                      ? {
                          indicator: (
                            <ReactSVG
                              src={LoadingSvg}
                              className="anticon-spin anticon myLoadingIcon"
                            />
                          ),
                        }
                      : false
                  }
                />
                {total >= 1 ? (
                  <div className="myPaginationSite">
                    <Pagination
                      className="myPagination"
                      showQuickJumper
                      total={total}
                      pageSize={pageSize}
                      current={pageNumber}
                      showSizeChanger={true}
                      onChange={onChange}
                      showTotal={(total) => `共 ${total} 条`}
                    />
                  </div>
                ) : null}
              </>
            ) : (
              <Table
                className="myFormTable"
                columns={tableColumns}
                rowKey={(record, id) => id}
                dataSource={
                  DBTableValue === "" ? DBTableList : chooseDBTableList
                }
                rowClassName={(record) =>
                  record.chi_name === rowId ? "table-tr-row-id" : ""
                }
                onRow={(record) => {
                  return {
                    onClick: (e) => {
                      setRowId(record.chi_name);
                    },
                  };
                }}
                pagination={{
                  pageSize: 12,
                  defaultCurrent: 1,
                  showQuickJumper: true,
                  showSizeChanger: false,
                  total:
                    DBTableValue === ""
                      ? DBTableList.length
                      : chooseDBTableList.length,
                  showTotal: (total) => `共 ${total} 条`,
                }}
                showHeader={false}
                locale={{
                  emptyText: <MyEmpty></MyEmpty>,
                }}
                loading={
                  loading
                    ? {
                        indicator: (
                          <ReactSVG
                            src={LoadingSvg}
                            className="anticon-spin anticon myLoadingIcon"
                          />
                        ),
                      }
                    : false
                }
              />
            )}
          </div>

          {!noBtn && (
            <div className="drawerFooterBtnBox btnBox">
              <Button
                className="noBg"
                onClick={() => {
                  handleCancel();
                }}
              >
                {cancelText || "关闭"}
              </Button>
              <Button
                style={StyleOptions.btnStyle}
                onClick={() => {
                  handleOk();
                }}
              >
                {okText || "添加"}
              </Button>
            </div>
          )}
        </div>
      </Modal>
    </ConfigProvider>
  );
};

export default wrapper(AddModal);
