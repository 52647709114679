import "./index.scss";
import Select from "react-select";
import { useEffect, useState, useRef } from "react";
import { ReactSVG } from "react-svg";
import { Drawer, Button, Tag, Input } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import HeaderTitle from "../../../../../components/HeaderTitle";
import Scrollbars from "react-custom-scrollbars";
import DropdownSvg from "../../../../../assets/下拉.svg";
import flowIcon from "../../../../../assets/流向.svg";
import sourceIcon from "../../../../../assets/来源.svg";
import RelationshipIcon from "../../../../../assets/隶属关系.svg";
import projectApi from "../../../../../apis/project";
import DetailTable from "../DetailTable";
import { useSelector } from "react-redux";
import MySpin from "../../../../../components/MySpin";

const { Option } = Select;

const DetailDrawer = (props) => {
  const {
    /* 关闭触发的回调 */
    onCancel,
    visible,
    /* 初始值 */
    defaultData,
    /* 版本列表 */
    metadataVersoList,
    /* 血缘关系 */
    relationList,
    loading,
    getmetaDataInfo, // 切换版本获取元数据
    chooseList,
    metaDataCode, // 元数据类型选择
    MetadataStorageList, // 数据存储列表
  } = props;
  const DetailDrawerRef = useRef(null);

  // 数据存储映射名
  const dbName = MetadataStorageList?.find(
    (item) => item.metadata_id === defaultData?.ds_metadata_id
  );

  const [managementResList, setManagementResList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const dicCodeInfoList = useSelector((state) => state.Project.dicCodeInfoList);

  // 数据分层主题域映射
  const layerSubList = useSelector((state) => state.Project.layerSubList);

  const handleCancel = () => {
    onCancel && onCancel();
  };

  // 获取管理责任人
  const getResponse = () => {
    if (defaultData?.metadata_type_code === "DS") {
      projectApi
        .getResponsible({
          metadata_type_code: defaultData?.metadata_type_code,
        })
        .then((res) => {
          if (res.code === 200) {
            setManagementResList(res.data);
          }
        });
    }
    if (defaultData?.data_layer_id !== null && defaultData?.sub_id !== null) {
      projectApi
        .getResponsible({
          metadata_type_code: defaultData?.metadata_type_code,
          data_layer_id: defaultData?.data_layer_id,
          sub_id: defaultData?.sub_id,
        })
        .then((res) => {
          if (res.code === 200) {
            setManagementResList(res.data);
          }
        });
    }
  };
  useEffect(() => {
    if (visible) {
      getResponse();
    }
  }, [defaultData]);

  useEffect(() => {
    if (visible) {
      setSelectedOption({
        value: defaultData?.metadata_ver_id,
        label: defaultData.ver_no,
      });
    }
    if (!visible) {
      setSelectedOption(null);
    }
  }, [visible, defaultData, metadataVersoList]);
  const existingStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "  border: 1px solid #d9d9d9;",
      width: "128px",
      height: "32px",
      boxShadow: "none",
    }),
    option: (provided, state) => ({
      ...provided,
      background: state.isSelected ? "#006AB2" : "#fff",
      color: state.isSelected ? "#fff" : "#313131",
      "&:hover": {
        background: "rgba(0, 0, 0, 0.08)",
      },
    }),
  };

  return (
    <Drawer
      title="查看元数据"
      placement="right"
      width="600px"
      className="MetaDetailDrawer"
      onClose={handleCancel}
      visible={visible}
      push={false}
      closable={false}
      destroyOnClose={true}
      mask={false}
      maskClosable={false}
      // zIndex={9999}
      extra={
        <CloseOutlined
          style={{ fontSize: "16px" }}
          onClick={() => {
            handleCancel();
          }}
        />
      }
      footer={
        <div className="drawerFooterBtnBox">
          <Button
            className="noBg"
            onClick={() => {
              handleCancel();
            }}
          >
            关闭
          </Button>
        </div>
      }
    >
      <Scrollbars
        autoHide={true}
        autoHideTimeout={5000}
        style={{
          height: `100%`,
          width: "100%",
        }}
      >
        <div className="detailsContent">
          {loading ? (
            <MySpin></MySpin>
          ) : (
            <>
              <HeaderTitle
                title="基本信息"
                extra={
                  <>
                    <span
                      className="metaState"
                      style={{
                        background:
                          defaultData?.metadata_stat_code === "NORMAL"
                            ? "#61D4AD"
                            : "#FE545F",
                      }}
                    >
                      {defaultData?.metadata_stat_code === "NORMAL"
                        ? "正常"
                        : "废弃"}
                    </span>
                    <span className="titleVenson">版本</span>
                    <Select
                      defaultValue={{ value: 2077, label: "v1.1" }}
                      options={metadataVersoList.map((item) => ({
                        value: item.metadata_ver_id,
                        label: item.ver_no,
                      }))}
                      value={selectedOption}
                      styles={existingStyles}
                      onChange={(selectedOption) => {
                        setSelectedOption(selectedOption);
                        getmetaDataInfo(
                          false,
                          selectedOption?.value,
                          {
                            metadata_type_code: defaultData?.metadata_type_code,
                          },
                          defaultData?.metadata_type_code
                        );
                      }}
                      components={{
                        DropdownIndicator: () => (
                          <div className="custom-select-arrow">
                            <ReactSVG
                              src={DropdownSvg}
                              style={{ width: "16px", height: "16px" }}
                            />
                          </div>
                        ),
                      }}
                    />
                    {/* <Select
                      placeholder="请选择"
                      showSearch={true}
                      labelInValue={true}
                      suffixIcon={
                        <ReactSVG
                          src={DropdownSvg}
                          style={{ width: "16px", height: "16px" }}
                        ></ReactSVG>
                      }
                      defaultValue={defaultData?.ver_no}
                      style={{ width: "128px" }}
                      onChange={(e) =>
                        getmetaDataInfo({ metadata_ver_id: e.key })
                      }
                    >
                      {metadataVersoList?.map((item) => {
                        return (
                          <Option
                            value={item.ver_no}
                            key={item.metadata_ver_id}
                          >
                            {item.ver_no}
                          </Option>
                        );
                      })}
                    </Select> */}
                  </>
                }
              ></HeaderTitle>
              <div className="detailsBox">
                <div className="detailItem">
                  <div className="detailKey">元数据类型</div>
                  <div className="detailValue">
                    {defaultData?.metadata_type_code &&
                      dicCodeInfoList?.DG_METADATA_TYPE?.find(
                        (item) => item[defaultData?.metadata_type_code]
                      )[defaultData?.metadata_type_code]}
                    {/* {
                      dicCodeInfoList?.DG_METADATA_TYPE?.find(
                        (item) => item[metaDataCode]
                      )[metaDataCode]
                    } */}
                  </div>
                </div>
                <div className="detailItem">
                  <div className="detailKey">中文名称</div>
                  <div className="detailValue">{defaultData?.chi_name}</div>
                </div>

                <div className="detailItem">
                  <div className="detailKey">英文名称 </div>
                  <div className="detailValue">{defaultData?.eng_name}</div>
                </div>
                {/* 数据分层和主题域 */}
                {defaultData?.metadata_type_code &&
                defaultData?.metadata_type_code !== "DS" &&
                Object.values(defaultData).length !== 0 &&
                Object.values(layerSubList?.DATA_LAYER_RLS).find(
                  (item) => item?.data_layer_id === defaultData?.data_layer_id
                ) !== undefined ? (
                  <>
                    <div className="detailItem">
                      <div className="detailKey">数据分层 </div>
                      <div className="detailValue">
                        {Object.values(defaultData).length !== 0
                          ? Object.values(layerSubList?.DATA_LAYER_RLS).find(
                              (item) =>
                                item?.data_layer_id ===
                                defaultData?.data_layer_id
                            ).data_layer_name
                          : null}
                      </div>
                    </div>
                    <div className="detailItem">
                      <div className="detailKey">主题域 </div>
                      <div className="detailValue">
                        {Object.values(defaultData).length !== 0
                          ? Object.values(layerSubList?.SUB_RLS).find(
                              (item) => item?.sub_id === defaultData?.sub_id
                            )?.sub_name
                          : null}
                      </div>
                    </div>
                  </>
                ) : null}

                {(defaultData?.metadata_type_code &&
                  defaultData?.metadata_type_code === "DS") ||
                (defaultData?.metadata_type_code &&
                  defaultData?.metadata_type_code === "TABLE") ? (
                  <>
                    <div className="detailItem">
                      <div className="detailKey">存储类型 </div>
                      <div className="detailValue">
                        {defaultData?.db_type_code_value}
                      </div>
                    </div>
                  </>
                ) : null}

                <div className="detailItem">
                  <div className="detailKey">描述 </div>
                  <div className="detailValue">
                    {defaultData?.metadata_desc}
                  </div>
                </div>

                <div className="detailItem">
                  <div className="detailKey">标签 </div>
                  <div className="detailValue">
                    {chooseList?.map((item, index) => (
                      <Tag key={index}>{item.tag_name}</Tag>
                    ))}
                  </div>
                </div>
              </div>

              <HeaderTitle title="技术信息"></HeaderTitle>

              <div className="detailsBox">
                {/* 网址-web */}
                {defaultData?.metadata_type_code &&
                defaultData?.metadata_type_code === "WEB" ? (
                  <div className="detailItem">
                    <div className="detailKey">网址</div>
                    <div className="detailValue">
                      <a
                        href={defaultData?.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {defaultData?.url}
                      </a>
                    </div>
                  </div>
                ) : null}
                {/* 微信公众号名称-web */}
                {defaultData?.metadata_type_code &&
                defaultData?.metadata_type_code === "WECHAT_ACC" ? (
                  <div className="detailItem">
                    <div className="detailKey">名称</div>
                    <div className="detailValue">
                      {defaultData?.wechat_name}
                    </div>
                  </div>
                ) : null}

                {/* ds */}
                {defaultData?.metadata_type_code &&
                defaultData?.metadata_type_code === "DS" ? (
                  <>
                    <div className="detailItem">
                      <div className="detailKey">Host</div>
                      <div className="detailValue">
                        {defaultData?.db_server}
                      </div>
                    </div>
                    <div className="detailItem">
                      <div className="detailKey">DataBase</div>
                      <div className="detailValue">
                        {defaultData?.db_database}
                      </div>
                    </div>
                    <div className="detailItem">
                      <div className="detailKey">Password</div>
                      <div className="detailValue">
                        {`${defaultData?.db_password?.substring(
                          0,
                          3
                        )}*******${defaultData?.db_password?.substring(
                          defaultData?.db_password?.length - 3
                        )}`}
                        {/* {defaultData?.db_password} */}
                      </div>
                    </div>
                    <div className="detailItem">
                      <div className="detailKey">Port</div>
                      <div className="detailValue">{defaultData?.db_port}</div>
                    </div>
                  </>
                ) : null}

                {/* api */}
                {defaultData?.metadata_type_code &&
                defaultData?.metadata_type_code === "API" ? (
                  <>
                    <div className="detailItem">
                      <div className="detailKey">接口URL</div>
                      <div className="detailValue">{defaultData?.api_url}</div>
                    </div>
                    <div className="detailItem">
                      <div className="detailKey">请求方式</div>
                      <div className="detailValue">
                        {defaultData?.req_method_code}
                      </div>
                    </div>
                    <div className="detailItem">
                      <div className="detailKey">Content-Type</div>
                      <div className="detailValue">
                        {defaultData?.content_type}
                      </div>
                    </div>
                    <div className="detailItem">
                      <div className="detailKey">请求参数及说明 </div>
                      <div className="detailValue">
                        {defaultData?.req_parm_exp}
                      </div>
                    </div>
                    <div className="detailItem">
                      <div className="detailKey">响应及说明 </div>
                      <div className="detailValue">{defaultData?.resp_exp}</div>
                    </div>
                  </>
                ) : null}

                {/* table */}
                {defaultData?.metadata_type_code &&
                defaultData?.metadata_type_code === "TABLE" ? (
                  <>
                    <div className="detailItem">
                      <div className="detailKey">数据存储</div>
                      <div className="detailValue">
                        {`${dbName?.chi_name} / ${dbName?.eng_name}`}
                      </div>
                    </div>

                    <div
                      className="detailItem"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div className="detailKey">模型定义 </div>
                      <div className="detailValue" style={{ width: "80px" }}>
                        {/* <Button className="checkBtn btn">查看DDL</Button> */}
                      </div>
                    </div>
                    <div className="detailItem">
                      <DetailTable
                        flag={true}
                        dbTableList={defaultData?.tableInfo}
                      ></DetailTable>
                    </div>
                  </>
                ) : null}

                {/* 血缘关系 */}
                {defaultData?.metadata_type_code &&
                defaultData?.metadata_type_code !== "DS" ? (
                  <div className="detailItem">
                    <div className="detailKey">血缘关系</div>
                    <div className="detailValue">
                      {relationList?.map((item, index) => (
                        <div className="relationshipDetail" key={index}>
                          <ReactSVG
                            src={
                              item.cons_type_code === "SOURCE"
                                ? sourceIcon
                                : flowIcon
                            }
                            className="sourceIcon"
                          ></ReactSVG>
                          <div> {`${item.chi_name} / ${item.eng_name}`}</div>
                        </div>
                      ))}
                    </div>
                    {/* <ReactSVG
                      src={RelationshipIcon}
                      style={{ marginRight: "8px" }}
                    ></ReactSVG> */}
                  </div>
                ) : null}
              </div>
              <HeaderTitle title="管理信息"></HeaderTitle>
              <div className="detailsBox">
                <div className="detailItem">
                  <div className="detailKey">管理责任人</div>
                  <div className="detailValue">
                    {managementResList?.da_rp_id_value ||
                      managementResList?.def_da_rp_id_value}
                  </div>
                </div>
                <div className="detailItem managementInfo">
                  <div className="detailKey">创建人</div>
                  <div className="detailValue">
                    {defaultData?.created_by_id_value}
                  </div>
                </div>
                <div className="detailItem managementInfo">
                  <div className="detailKey">创建时间</div>
                  <div className="detailValue">{defaultData?.created_time}</div>
                </div>
                <div className="detailItem managementInfo">
                  <div className="detailKey"></div>
                  <div className="detailValue"></div>
                </div>
                <div className="detailItem managementInfo">
                  <div className="detailKey">更新时间</div>
                  <div className="detailValue">{defaultData?.updated_time}</div>
                </div>
              </div>
            </>
          )}
        </div>
      </Scrollbars>
    </Drawer>
  );
};

export default DetailDrawer;
