import { Menu, Layout } from "antd";
import "./index.scss";
import { useEffect, useState } from "react";

const { Sider } = Layout;

export default function Sidebar(props) {
  const {
    setBreadcrumbName,
    setBreadcrumb,
    setLoading,
    apiName,
    setApiName,
    selectName, // 搜索输入的接口名称
    menuList,
  } = props;
  const [selectedKeys, setSelectedKeys] = useState(["开通服务"]); // 初始化选中的菜单项
  const [openKeys, setOpenKeys] = useState(["快速入门"]); // 初始化展开的菜单项
  const initMenu = (list, parentKey = "") => {
    list = list?.map((item, index) => {
      const uniqueKey = `${item.breadcrumb}-${item.menu_name}`;
      if (item.children && item.children.length > 0) {
        return (
          <Menu.SubMenu title={item.menu_name} key={item.menu_name}>
            {initMenu(item.children)}
          </Menu.SubMenu>
        );
      }

      const isAlphaNumeric = /^[a-z0-9]+$/.test(selectName);
      // if (!isAlphaNumeric) console.log(selectName);
      const renderMenuName = (menuName) => {
        if (selectName) {
          const parts = menuName.split(new RegExp(`(${selectName})`, "gi")); // 按照 selectName 切割字符串

          return parts.map((part, idx) =>
            part.toLowerCase() === selectName.toLowerCase() ? (
              <span key={idx} style={{ color: "#fe545f", fontWeight: "bold" }}>
                {part}
              </span>
            ) : (
              part
            )
          );
        }
        return menuName;
      };

      return (
        <Menu.Item
          key={item.menu_name}
          onClick={() => {
            setBreadcrumbName(item.menu_name);
            setBreadcrumb(item);
            setLoading(true);
            setSelectedKeys([item.menu_name]);
            setApiName(null);
          }}
        >
          <span>{renderMenuName(item.menu_name)}</span>
        </Menu.Item>
      );
    });
    return list;
  };
  // 添加逻辑：查找匹配的菜单项并触发点击事件
  const handleClickMenu = (menuName, flag) => {
    const result = props.routerList[1]?.children?.reduce((acc, item) => {
      if (item.children && item.children.length > 0) {
        acc.push(...item.children);
      }
      return acc;
    }, []);
    const matchedItem = result.find(
      (item) => item.menu_name === menuName || item.api_id === menuName
    );

    if (matchedItem) {
      setBreadcrumbName(matchedItem.menu_name);
      setBreadcrumb(matchedItem);
      setLoading(true);
      setSelectedKeys([matchedItem.menu_name]);
      if (flag) {
        setOpenKeys(["API参考"]);
      } else {
        setOpenKeys(["API参考", matchedItem.breadcrumb]);
      }
    }
  };

  // 在组件挂载时触发点击事件
  useEffect(() => {
    updateselectName();
  }, [menuList]);

  const updateselectName = () => {
    if (selectName) {
      const flag = menuList?.find(
        (v) => v.menu_name === selectName || v.api_id === selectName
      );

      if (menuList?.length !== 0) {
        if (flag) {
          handleClickMenu(selectName);
        } else {
          handleClickMenu(menuList[0]?.menu_name, true);
        }
      } else {
        setBreadcrumbName("开通服务");
        setLoading(true);
        setSelectedKeys(["开通服务"]);
        setOpenKeys(["API参考"]);
      }
    }
  };

  return (
    <div className="mySidebarContent">
      <Sider
        trigger={null}
        width={275}
        style={{
          height: "calc(100% - 40px)",
          background: "#fff",
        }}
        className="site-layout-background"
      >
        <div
          className="title"
          style={{
            background: "linear-gradient(223deg, #006bb3 0%, #15d2d6 100%)",
          }}
        >
          数据服务
        </div>

        <Menu
          mode="inline"
          style={{
            height: "100%",
            borderRight: 0,
            overflow: "auto",
            background: "#fff",
          }}
          selectedKeys={selectedKeys}
          openKeys={openKeys} // 设置展开的菜单项
          onOpenChange={(keys) => setOpenKeys(keys)} // 处理展开菜单项的变化
        >
          {initMenu(
            props.routerList?.filter((item) => !item.hide),
            false
          )}
        </Menu>
      </Sider>
    </div>
  );
}
