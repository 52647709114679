import "./index.scss";
import { Layout } from "antd";
import MyHeader from "../components/Header";
import { useEffect, useState } from "react";
import MySidebar from "../components/MySidebar";
import { useDispatch } from "react-redux";
import MySpin from "../../../components/MySpin";
import projectApi from "../../../apis/project";
import OpenService from "../OpenService";
import CreateApplication from "../CreateApplication";
import AutMechanism from "../AutMechanism";
import ApiContent from "../ApiContent";
import { setNewselectName } from "../../../store/project/project";
import { debounce } from "../../../unit/unit";
const { Content } = Layout;

const Home = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [router, setRouter] = useState([
    {
      menu_name: "快速入门",
      code: 1,
      children: [
        {
          menu_name: "开通服务",
          breadcrumb: "快速入门",
          component: <OpenService></OpenService>,
          code: 2,
        },
        {
          menu_name: "创建应用",
          breadcrumb: "快速入门",
          component: <CreateApplication></CreateApplication>,
          code: 2,
        },
        {
          menu_name: "鉴权认证机制",
          breadcrumb: "快速入门",
          component: <AutMechanism></AutMechanism>,
          code: 2,
        },
      ],
    },
  ]);
  const [breadcrumbName, setBreadcrumbName] = useState("开通服务");
  const [breadcrumb, setBreadcrumb] = useState({
    menu_name: "开通服务",
    code: 2,
    breadcrumb: "快速入门",
  });
  const [prefix, setPrefix] = useState("");
  const [newRouter, setNewRouter] = useState([]);
  const [menuList, setMenuList] = useState([]); // 快速检索接口使用
  const [apiName, setApiName] = useState(null); // 选中接口
  const [selectName, setSelectName] = useState(""); // 搜索输入的接口名称
  const flatten = (data) => {
    return data.reduce((result, item) => {
      return result.concat(
        item,
        Array.isArray(item.children) ? flatten(item.children) : []
      );
    }, []);
  };

  useEffect(() => {
    setNewRouter(flatten(router));
  }, [router]);

  dispatch(setNewselectName(selectName));

  useEffect(() => {
    fetchApiData({
      key_word: selectName,
    });
  }, [selectName]);

  const fetchApiData = async (data) => {
    try {
      const res = await projectApi.getAllApiList(data);

      if (res.code === 200) {
        const result = res.data?.flatMap(
          (item) =>
            item.api_list?.map((child) => ({
              menu_name: child.chi_name,
              api_id: child.api_id.trim(),
            })) || []
        );

        setMenuList(result);

        const routerData = res.data.map((item) => ({
          menu_name: item.chi_name,
          breadcrumb: item.chi_name,
          code: 2,
          children:
            item.api_list?.map((child) => ({
              menu_name: child.chi_name,
              breadcrumb: item.chi_name,
              code: 3,
              api_id: child.api_id,
              component: <ApiContent id={child.api_id} />,
            })) || [],
        }));

        setRouter((prevRouter) => [
          {
            menu_name: "快速入门",
            code: 1,
            children: [
              {
                menu_name: "开通服务",
                breadcrumb: "快速入门",
                component: <OpenService></OpenService>,
                code: 2,
              },
              {
                menu_name: "创建应用",
                breadcrumb: "快速入门",
                component: <CreateApplication></CreateApplication>,
                code: 2,
              },
              {
                menu_name: "鉴权认证机制",
                breadcrumb: "快速入门",
                component: <AutMechanism></AutMechanism>,
                code: 2,
              },
            ],
          },
          {
            menu_name: "API参考",
            code: 1,
            children: routerData,
          },
        ]);
      }
    } catch (error) {
      console.error("Error fetching API list:", error);
    }
  };
  useEffect(() => {
    if (breadcrumb.code === 1) {
      setPrefix(`技术文档 / 快速入门 / `);
    } else if (breadcrumb.code === 2) {
      setPrefix(`技术文档 / ${breadcrumb.breadcrumb} /`);
    } else if (breadcrumb.code === 3) {
      setPrefix(`技术文档 / API参考 / ${breadcrumb.breadcrumb} / `);
    }
  }, [breadcrumb]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    // 清除定时器
    return () => clearInterval(timer);
  }, [loading]);

  // 展示组件
  const renderComponent = (breadcrumbName) => {
    return newRouter?.find((item) => item.menu_name === breadcrumbName)
      ?.component;
  };

  return (
    <div className="TecDocumentation">
      <Layout className="my-layout">
        <MyHeader
          menuList={menuList}
          apiName={apiName}
          setApiName={setApiName}
          setSelectName={setSelectName}
          selectName={selectName}
        ></MyHeader>
        <Layout>
          <MySidebar
            routerList={router}
            setBreadcrumbName={setBreadcrumbName}
            setBreadcrumb={setBreadcrumb}
            setLoading={setLoading}
            apiName={apiName}
            setApiName={setApiName}
            selectName={selectName}
            menuList={menuList}
          ></MySidebar>

          {/* 内容部分 */}
          <Content className="my-content">
            <div className="contentBox">
              <div className="contentTitle">
                {/* 面包屑 */}
                <div className="breadcrumb">
                  <span style={{ color: "rgba(0, 0, 0, 0.3)" }}>{prefix}</span>
                  &nbsp;{breadcrumbName}
                </div>
                <div className="homeTitle">{breadcrumbName}</div>
              </div>
              {loading ? <MySpin></MySpin> : renderComponent(breadcrumbName)}
            </div>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};
export default Home;
