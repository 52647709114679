import "./index.scss";
import { useEffect, useState, useRef } from "react";
import { ReactSVG } from "react-svg";
import projectApi from "../../../../../apis/project";
import {
  Drawer,
  Button,
  Form,
  Input,
  Select,
  InputNumber,
  message,
  Space,
  Dropdown,
  Menu,
} from "antd";
import {
  CloseOutlined,
  PlusCircleFilled,
  MinusCircleFilled,
  CheckCircleFilled,
  CloseCircleFilled,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import Scrollbars from "react-custom-scrollbars";
import HeaderTitle from "../../../../../components/HeaderTitle";
import DropdownSvg from "../../../../../assets/下拉.svg";
import flowIcon from "../../../../../assets/流向.svg";
import sourceIcon from "../../../../../assets/来源.svg";
import AddModal from "../AddModal";
import DetailTable from "../DetailTable";
import { debounce } from "../../../../../unit/unit";
import MySpin from "../../../../../components/MySpin";

const { TextArea } = Input;
const { Option } = Select;

const DialogDrawer = (props) => {
  const {
    /* 标题 */
    drawerTitle,
    /* 关闭触发的回调 */
    onCancel,
    /* 确定触发的回调 */
    onOk,
    /* 确认按钮文字 */
    okText,
    /* 取消按钮文字 */
    cancelText,
    visible,
    /* 判断新增还是修改 */
    addNew,
    setAddNew,
    /* 初始值 */
    defaultData,
    layerList,
    /* 元数据类型列表 */
    metaDataType,
    /* 主题域最新的版本 */
    subId,
    /* 元数据类型选择 */
    metaDataCode,
    setDialogDrawerVisible,
    /* 更新数据 */
    getMetadataList,
    MetadataStorageList, // 数据存储列表
    tagList,
    loading,
    setMetaDrawerLoading,
    setNewDBTypeCode,
    newDBTypeCode,
    copied, // 数据存储一键复制的内容
    setCopied,
    setCopiedFlag,
    copiedFlag,
  } = props;

  const [form] = Form.useForm();
  const detailTableRef = useRef();
  const dicCodeInfoList = useSelector((state) => state.Project.dicCodeInfoList);

  // 数据分层主题域映射
  const layerSubList = useSelector((state) => state.Project.layerSubList);

  const [subList, setSubList] = useState([]); // 主题域列表(模糊搜索使用)
  const [subData, setSubData] = useState([]); // 主题域列表
  const [metaCode, setMetaCode] = useState("WEB"); // 元数据类型选择
  const [layerCode, setLayerCode] = useState(null); // 数据分层选择
  const [subCode, setSubCode] = useState(null); // 数据分层选择
  // const [DBTypeCode, setDBTypeCode] = useState(null); // 存储类型选择
  const [testClick, setTestClick] = useState(false); // 判断点击测试还是录入
  const [relationList, setRelationList] = useState([]);
  const [tagInfo, setTagInfo] = useState([]);
  const [chooseTag, setChooseTag] = useState("");
  const [total, setTotal] = useState(10);
  const [chooseList, setChooseList] = useState([]);
  const [dbCode, setDbCode] = useState(null); // 数据存储id
  const [schema, setSchema] = useState(null); // schema
  const [dbTableList, setDbTableList] = useState({}); // 数据存储表单项
  const [dbTableId, setDbTableId] = useState(""); // 数据存储表单名
  const [DbSchemasList, setDbSchemasList] = useState([]); // Schemas 信息

  // 请求方式
  const reqMethodType = dicCodeInfoList?.DG_API_REQ_METHOD?.map((item) =>
    Object.keys(item)
  ).flat();

  // content-type
  const contentTypeType = dicCodeInfoList?.DG_API_CONTENT_TYPE?.map((item) =>
    Object.keys(item)
  ).flat();

  // 存储类型
  const DBType = dicCodeInfoList?.DG_DB_TYPE?.map((item) =>
    Object.keys(item)
  ).flat();

  // 获取schemas信息
  const getDbSchemas = (id) => {
    projectApi.getDbSchemas(id).then((res) => {
      if (res.code === 200) {
        setDbSchemasList(res.data);
      } else {
        setDbSchemasList([]);
      }
    });
  };
  // 血缘关系查询
  useEffect(() => {
    if (addNew === false && metaCode !== "DS" && visible === true) {
      projectApi
        .getBloodrRelation({
          metadata_ver_id: defaultData?.metadata_ver_id,
        })
        .then((res) => {
          if (res.code === 200) {
            setRelationList(res.data);
          } else {
            setRelationList([]);
          }
        });
    }

    if (visible === true && addNew === false) {
      setTagInfo(tagList);
      // 获取标签信息
      projectApi
        .getMetadataTag({ metadata_ver_id: defaultData?.metadata_ver_id })
        .then((res) => {
          if (res.code === 200) {
            const data = res?.data[defaultData?.metadata_ver_id].reduce(
              (cur, pre) => [
                ...cur,
                {
                  disabled: undefined,
                  key: pre?.tag_id,
                  label: pre.tag_name,
                  value: pre.tag_id,
                },
              ],
              []
            );
            setChooseList(data);
            setMetaDrawerLoading(false);
          } else {
            setChooseList([]);
            setMetaDrawerLoading(false);
          }
        });
    }
  }, [defaultData]);

  useEffect(() => {
    if (visible === true && addNew === true) {
      setTagInfo(tagList);
      setChooseList([]);
    }
    if (visible === false) {
      setDbCode(null);
      setSchema(null);
      setDbTableList({});
      setCopiedFlag(false);
      setCopied({});
      setDbTestCode(null);
      setDbTest(true);
    }
    if (metaCode === "TABLE") {
      form.setFieldsValue({
        meta_table: detailTableRef.current?.data,
      });
    }
    // console.log(copiedFlag, copied);
  }, [visible]);

  // 获取主题域已发布标准
  const getPublishedList = (layerCode) => {
    projectApi
      .getSubPublishedList(
        { ver_no: subId, data_layer_id: layerCode },
        { orders: [] }
      )
      .then((res) => {
        if (res.code === 200) {
          const data = res.data.items.map((item) => {
            return {
              sub_id: item.sub_id,
              sub_name: item.sub_name,
            };
          });
          let newArr = [];
          let arrId = [];
          for (let item of data) {
            if (arrId.indexOf(item["sub_id"]) === -1) {
              arrId.push(item["sub_id"]);
              newArr.push(item);
            }
          }
          setSubList(newArr);
          setSubData(newArr);
        } else {
          setSubList([]);
          setSubData();
        }
      });
  };

  const menu = (
    <Menu
      items={[
        {
          key: "SOURCE",
          label: <div>添加来源</div>,
          icon: (
            <ReactSVG
              src={sourceIcon}
              style={{ width: "16px", height: "16px" }}
            ></ReactSVG>
          ),
          onClick: (value) => {
            AddModal({
              maskClosable: true,
              noIcon: true,
              noBtn: false,
              title: "添加来源",
              metaDataType: metaDataType,
              dicCodeInfoList: dicCodeInfoList,
              layerList,
              metaDataCode,
              layerSubList,
              relationList,
              onOk: (value) => {
                handleAddItem(value, "SOURCE");
              },
            });
          },
        },
        {
          key: "FLOW",
          label: <div>添加流向</div>,
          icon: (
            <ReactSVG
              src={flowIcon}
              style={{ width: "16px", height: "16px" }}
            ></ReactSVG>
          ),
          onClick: () =>
            AddModal({
              maskClosable: true,
              noIcon: true,
              noBtn: false,
              title: "添加流向",
              metaDataType: metaDataType,
              dicCodeInfoList: dicCodeInfoList,
              layerList,
              metaDataCode,
              layerSubList,
              relationList,
              onOk: (value) => {
                handleAddItem(value, "FLOW");
              },
            }),
        },
      ]}
    />
  );

  useEffect(() => {
    form.resetFields();
    if (metaCode !== "DS" && visible === true) {
      getPublishedList(defaultData?.data_layer_id);
    }
    if (addNew === false) {
      setLayerCode(defaultData?.data_layer_id);
      setSubCode(defaultData?.sub_id);
    }
    if (metaCode === "TABLE" && addNew === false) {
      setDbTableList(defaultData?.tableInfo);
    }
  }, [defaultData]);

  const handleCancel = () => {
    onCancel && onCancel();
  };

  // 提交表单录入
  const onFinish = (value) => {
    const tagNameList = chooseList
      ?.filter((item) => Object.keys(item).length !== 4)
      ?.reduce((pre, cur) => [...pre, { tag_name: cur.label }], []);
    const tagIdList = chooseList
      ?.filter((item) => Object.keys(item).length === 4)
      ?.reduce((pre, cur) => [...pre, { tag_id: cur.value }], []);

    const pick = [
      "db_type_code",
      "db_server",
      "db_database",
      "db_username",
      "db_password",
      "db_port",
    ];
    setDbTestList((data) =>
      pick.reduce((data, item) => {
        if (item in value) {
          data[item] = value[item];
        }
        return data;
      }, {})
    );
    const newMetaCons = relationList?.reduce(
      (prev, cur) => [
        ...prev,
        {
          cons_type_code: cur.cons_type_code,
          rel_metadata_id: cur.rel_metadata_id,
        },
      ],
      []
    );

    if (testClick === false && addNew) {
      onOk && onOk(value, metaCode, chooseList, dbTableList, dbTableId);
    }
    if (testClick === false && addNew === false) {
      if (value.metadata_type_code === "API") {
        projectApi
          .updateMetadataApi({
            meta_data: {
              metadata_id: defaultData.metadata_id,
            },
            meta_ver: {
              chi_name: value.chi_name,
              eng_name: value.eng_name,
              metadata_desc: value.metadata_desc,
            },
            meta_api: {
              data_layer_id: value.data_layer_id,
              sub_id: value.sub_id,
              api_url: value.api_url,
              req_method_code: value.req_method_code,
              content_type: value.content_type,
              req_parm_exp: value.req_parm_exp,
              resp_exp: value.resp_exp,
            },
            meta_tag: [...tagIdList, ...tagNameList],
            meta_cons: newMetaCons,
          })
          .then((res) => {
            if (res.code === 200) {
              setDialogDrawerVisible(false);
              getMetadataList();
              message.success(res.message);
            } else {
              message.error(res.message);
            }
          });
      } else if (value.metadata_type_code === "WEB") {
        projectApi
          .updateMetadataWeb({
            meta_data: {
              metadata_id: defaultData.metadata_id,
            },
            meta_ver: {
              chi_name: value.chi_name,
              eng_name: value.eng_name,
              metadata_desc: value.metadata_desc,
            },
            meta_web: {
              data_layer_id: value.data_layer_id,
              sub_id: value.sub_id,
              url: value.url,
            },
            meta_tag: [...tagIdList, ...tagNameList],
            meta_cons: newMetaCons,
          })
          .then((res) => {
            if (res.code === 200) {
              setDialogDrawerVisible(false);
              getMetadataList();
              message.success(res.message);
            } else {
              message.error(res.message);
            }
          });
      } else if (value.metadata_type_code === "WECHAT_ACC") {
        projectApi
          .updateMetadataWeChat({
            meta_data: {
              metadata_id: defaultData.metadata_id,
            },
            meta_ver: {
              chi_name: value.chi_name,
              eng_name: value.eng_name,
              metadata_desc: value.metadata_desc,
            },
            meta_wechat_acc: {
              data_layer_id: value.data_layer_id,
              sub_id: value.sub_id,
              wechat_name: value.wechat_name,
            },
            meta_tag: [...tagIdList, ...tagNameList],
            meta_cons: newMetaCons,
          })
          .then((res) => {
            if (res.code === 200) {
              setDialogDrawerVisible(false);
              getMetadataList();
              message.success(res.message);
            } else {
              message.error(res.message);
            }
          });
      } else if (value.metadata_type_code === "DS") {
        projectApi
          .updateMetadataDb({
            meta_data: {
              metadata_id: defaultData.metadata_id,
            },
            meta_ver: {
              chi_name: value.chi_name,
              eng_name: value.eng_name,
              metadata_desc: value.metadata_desc,
            },
            meta_db: {
              db_server: value.db_server,
              db_database: value.db_database,
              db_username: value.db_username,
              db_password: value.db_password,
              db_port: value.db_port,
            },
            meta_tag: [...tagIdList, ...tagNameList],
            meta_db_type: {
              db_type_code: value.db_type_code,
            },
          })
          .then((res) => {
            if (res.code === 200) {
              setDialogDrawerVisible(false);
              getMetadataList();
              message.success(res.message);
            } else {
              message.error(res.message);
            }
          });
      } else if (value.metadata_type_code === "TABLE") {
        projectApi
          .updateMetadataTable({
            meta_data: {
              metadata_id: defaultData.metadata_id,
            },
            meta_ver: {
              chi_name: value.chi_name,
              eng_name: value.eng_name,
              metadata_desc: value.metadata_desc,
            },
            meta_table: {
              table_info: {
                data_layer_id: value.data_layer_id,
                sub_id: value.sub_id,
                table_name: value.eng_name,
                table_cmnt: value.chi_name,
                db_type_code: value.db_type_code,
              },
              ...dbTableList,
              table_store: {
                ds_metadata_id: defaultData?.ds_metadata_id,
                ds_schema: defaultData?.ds_schema,
              },
            },
            meta_tag: [...tagIdList, ...tagNameList],
            meta_db_type: {
              db_type_code: value.db_type_code,
            },
            meta_cons: newMetaCons,
          })
          .then((res) => {
            if (res.code === 200) {
              setDialogDrawerVisible(false);
              getMetadataList();
              message.success(res.message);
            } else {
              message.error(res.message);
            }
          });
      }
    }
  };

  const [dbTest, setDbTest] = useState(true); // 是否测试连接
  const [dbTestList, setDbTestList] = useState(null); // 测试连接对象
  const [dbTestCode, setDbTestCode] = useState(null); // 测试连接value
  const [managementResList, setManagementResList] = useState(null);

  // 连接测试
  useEffect(() => {
    if (metaCode === "DS") {
      dbTestconnection();
    }
  }, [dbTestList]);

  // 数据库连接测试
  const dbTestconnection = () => {
    if (dbTestList !== null) {
      let { db_type_code, ...data } = dbTestList;
      projectApi
        .dbTestconnection({ db_type_code: dbTestList.db_type_code }, data)
        .then((res) => {
          if (res.code === 200) {
            setDbTestCode(res.data);
            res.data ? setDbTest(false) : setDbTest(true);
          } else {
            message.error(res.message);
          }
        });
    }
  };

  // 自定义动态新增add方法
  const handleAddItem = (value, key) => {
    const data = value?.map((item) => {
      return {
        key: item.metadata_id,
        cons_type_code: key,
        rel_metadata_id: item.metadata_id,
        chi_name: item.chi_name,
        eng_name: item.eng_name,
      };
    });
    const list = form.getFieldValue("meta_cons") || relationList;
    const newData = list?.concat(data);
    // 使用 reduce 方法和一个临时对象来去重
    const uniqueArray = newData.reduce(function (acc, obj) {
      const key = obj.rel_metadata_id;
      if (!acc[key]) {
        acc[key] = obj;
      }
      return acc;
    }, {});

    // 将去重后的对象转换为数组
    const result = Object.values(uniqueArray);
    setRelationList(result);
    form.setFieldsValue({
      meta_cons: result,
    });
  };

  // 自定义动态删除remove方法
  const handleRemoveItem = (value) => {
    const list = form.getFieldValue("meta_cons") || relationList;
    const nextList = list?.filter(
      (item) => item.rel_metadata_id !== value.rel_metadata_id
    );
    setRelationList(nextList);
    form.setFieldsValue({
      meta_cons: nextList,
    });
  };

  // 管理责任人
  useEffect(() => {
    if (layerCode !== null && subCode !== null && visible) {
      projectApi
        .getResponsible({
          metadata_type_code: metaCode,
          data_layer_id: layerCode,
          sub_id: subCode,
        })
        .then((res) => {
          if (res.code === 200) {
            setManagementResList(res.data);
          }
        });
    }
  }, [layerCode, subCode]);

  useEffect(() => {
    setRelationList([]);
    setMetaCode(metaDataCode);
    if (metaDataCode === "DS" && visible) {
      projectApi
        .getResponsible({
          metadata_type_code: metaCode,
        })
        .then((res) => {
          if (res.code === 200) {
            setManagementResList(res.data);
          }
        });
    }
  }, [metaDataCode, visible]);

  useEffect(() => {
    if (metaCode === "DS" && visible) {
      projectApi
        .getResponsible({
          metadata_type_code: metaCode,
        })
        .then((res) => {
          if (res.code === 200) {
            setManagementResList(res.data);
          }
        });
    }
  }, [metaCode]);

  // 搜索tag
  const handleValue = (e) => {
    if (e !== "") {
      projectApi.getMetadataTagList({ tag_name: e }).then((res) => {
        if (res.code === 200) {
          setTotal(res.data.total);
          setTagInfo(res.data.items);
          if (res.data.total === 0) {
            setChooseTag(e);
          }
        } else {
          setTagInfo([]);
        }
      });
    }
  };

  return (
    <Drawer
      title={drawerTitle}
      placement="right"
      width="700px"
      className="metaDialogDrawer"
      onClose={handleCancel}
      visible={visible}
      push={false}
      closable={false}
      destroyOnClose={true}
      maskClosable={false}
      extra={
        <CloseOutlined
          style={{ fontSize: "16px" }}
          onClick={() => {
            handleCancel();
          }}
        />
      }
      footer={
        <div className="drawerFooterBtnBox">
          {metaCode === "DS" ? (
            <Button
              onClick={() => {
                setTestClick(false);
                form.submit();
              }}
              disabled={dbTest}
              style={
                dbTest
                  ? {
                      background: "#DEDEDE",
                      borderColor: "#DEDEDE",
                      color: "#FFFFFF",
                    }
                  : { background: "#016DB4", color: "#fff" }
              }
            >
              {okText ? okText : "确认"}
            </Button>
          ) : (
            <Button
              onClick={() => {
                setTestClick(false);
                form.submit();
              }}
              style={{ background: "#016DB4", color: "#fff" }}
            >
              {okText ? okText : "确认"}
            </Button>
          )}

          <Button
            className="noBg"
            onClick={() => {
              handleCancel();
            }}
          >
            {cancelText ? cancelText : "取消"}
          </Button>
          {metaCode === "DS" ? (
            <>
              {dbTestCode ? (
                <CheckCircleFilled
                  style={{ color: "#61D4AD", marginRight: "345px" }}
                />
              ) : null}
              {dbTestCode === false ? (
                <CloseCircleFilled
                  style={{ color: "#FE545F", marginRight: "345px" }}
                />
              ) : null}
              <Button
                style={{
                  marginRight: dbTestCode !== null ? "5px" : "360px",
                }}
                onClick={() => {
                  setTestClick(true);
                  form.submit();
                }}
              >
                测试连接
              </Button>
            </>
          ) : null}
        </div>
      }
    >
      {loading ? (
        <MySpin></MySpin>
      ) : (
        <Scrollbars
          autoHide={true}
          autoHideTimeout={5000}
          style={{
            height: `100%`,
            width: "100%",
          }}
        >
          <Form
            form={form}
            autoComplete="off"
            layout="vertical"
            className="dialogDrawerForm"
            preserve={false}
            onFinish={onFinish}
          >
            <HeaderTitle title="基本信息"></HeaderTitle>

            <Form.Item
              label="元数据类型"
              name="metadata_type_code"
              rules={[
                {
                  required: true,
                  message: "请选择元数据类型",
                },
              ]}
              initialValue={metaDataCode}
            >
              <Select
                placeholder="请选择"
                allowClear
                showSearch={true}
                disabled={addNew === false}
                suffixIcon={
                  <ReactSVG
                    src={DropdownSvg}
                    style={{ width: "16px", height: "16px" }}
                  ></ReactSVG>
                }
                style={{ width: "640px" }}
                onChange={(value) => {
                  setMetaCode(value);
                }}
              >
                {metaDataType?.map((item) => {
                  return (
                    <Option value={item} key={item}>
                      {dicCodeInfoList?.DG_METADATA_TYPE?.map(
                        (value) => value[item]
                      )}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            {metaCode !== "DS" ? (
              <>
                <Form.Item
                  label="数据分层"
                  name="data_layer_id"
                  rules={[
                    {
                      required: true,
                      message: "请选择数据分层",
                    },
                  ]}
                  initialValue={addNew ? null : defaultData?.data_layer_id}
                >
                  <Select
                    placeholder="请选择"
                    showSearch
                    allowClear
                    showArrow="true"
                    disabled={
                      (addNew === false &&
                        defaultData?.db_type_code === "POSTGRESQL") ||
                      defaultData?.db_type_code === "MYSQL"
                    }
                    suffixIcon={
                      <ReactSVG
                        src={DropdownSvg}
                        style={{ width: "16px", height: "16px" }}
                      ></ReactSVG>
                    }
                    style={{ width: "300px" }}
                    onChange={(value) => {
                      setLayerCode(value);
                      setSubCode(null);
                      if (value !== undefined) {
                        getPublishedList(value);
                      }

                      form.setFieldsValue({
                        sub_id: null,
                      });
                      setSubList([]);
                    }}
                    options={layerList?.map((item) => ({
                      label: item.data_layer_name,
                      value: item.data_layer_id,
                    }))}
                  ></Select>
                </Form.Item>

                <Form.Item
                  label="主题域"
                  name="sub_id"
                  rules={[
                    {
                      required: true,
                      message: "请选择主题域",
                    },
                  ]}
                  initialValue={addNew ? null : defaultData?.sub_id}
                >
                  <Select
                    placeholder="请选择"
                    showSearch
                    allowClear
                    disabled={
                      (addNew === false &&
                        defaultData?.db_type_code === "POSTGRESQL") ||
                      defaultData?.db_type_code === "MYSQL"
                    }
                    filterOption={(input, option) =>
                      option.props.children
                        ?.toLowerCase()
                        ?.indexOf(input.toLowerCase()) >= 0
                    }
                    suffixIcon={
                      <ReactSVG
                        src={DropdownSvg}
                        style={{ width: "16px", height: "16px" }}
                      ></ReactSVG>
                    }
                    onChange={(value) => {
                      setSubList(subData);
                      setSubCode(value);
                    }}
                    onSearch={debounce((e) => {
                      if (e !== "") {
                        setSubList(
                          subList.filter((item) => item.sub_name.includes(e))
                        );
                      } else {
                        setSubList(subData);
                      }
                    }, 500)}
                    style={{ width: "300px" }}
                  >
                    {subList?.map((item) => {
                      return (
                        <Option value={item.sub_id} key={item.sub_id}>
                          {item.sub_name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </>
            ) : (
              <Form.Item
                label="存储类型"
                name="db_type_code"
                rules={[
                  {
                    required: true,
                    message: "请选择存储类型",
                  },
                ]}
                initialValue={
                  addNew
                    ? copiedFlag
                      ? copied?.db_type_code
                      : null
                    : defaultData?.db_type_code
                }
              >
                <Select
                  placeholder="请选择"
                  allowClear
                  suffixIcon={
                    <ReactSVG
                      src={DropdownSvg}
                      style={{ width: "16px", height: "16px" }}
                    ></ReactSVG>
                  }
                  style={{ width: "640px" }}
                >
                  {DBType?.map((item) => {
                    return (
                      <Option value={item} key={item}>
                        {dicCodeInfoList?.DG_DB_TYPE?.map(
                          (value) => value[item]
                        )}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            )}

            <Form.Item
              label="中文名称"
              name="chi_name"
              rules={[
                {
                  required: true,
                  message: "请输入中文名称，不超过100个字符",
                },
                {
                  pattern: /^^(.|\n){0,100}$/,
                  message: "中文名称不超过100个字符",
                },
              ]}
              initialValue={
                addNew
                  ? copiedFlag
                    ? copied?.chi_name
                    : null
                  : defaultData?.chi_name
              }
            >
              <Input
                allowClear
                // disabled={
                //   (addNew === false &&
                //     defaultData?.db_type_code === "POSTGRESQL") ||
                //   defaultData?.db_type_code === "MYSQL"
                // }
                placeholder="1~100个字符"
                style={{ width: "300px" }}
              />
            </Form.Item>

            <Form.Item
              label="英文名称"
              name="eng_name"
              rules={[
                {
                  required: true,
                  message: "请输入英文名称，不超过100个字符或符号",
                },
                {
                  pattern: /^^(.|\n){0,100}$/,
                  message: "英文名称不超过100个字符或符号",
                },
              ]}
              initialValue={
                addNew
                  ? copiedFlag
                    ? copied?.eng_name
                    : null
                  : defaultData?.eng_name
              }
            >
              <Input
                allowClear
                // disabled={
                //   (addNew === false &&
                //     defaultData?.db_type_code === "POSTGRESQL") ||
                //   defaultData?.db_type_code === "MYSQL"
                // }
                placeholder="1-100个英文字符或符号"
                style={{ width: "300px" }}
              />
            </Form.Item>

            {metaCode === "TABLE" ? (
              <Form.Item
                label="存储类型"
                name="db_type_code"
                rules={[
                  {
                    required: true,
                    message: "请选择存储类型",
                  },
                ]}
                initialValue={addNew ? null : defaultData?.db_type_code}
              >
                <Select
                  placeholder="请选择"
                  allowClear
                  disabled={addNew === false && metaCode === "TABLE"}
                  showSearch={true}
                  suffixIcon={
                    <ReactSVG
                      src={DropdownSvg}
                      style={{ width: "16px", height: "16px" }}
                    ></ReactSVG>
                  }
                  onChange={(e) => {
                    setNewDBTypeCode(e);
                    setDbTableList({});
                    setDbCode(null);
                    setSchema(null);
                    setDbSchemasList([]);
                    form.setFieldsValue({
                      ds_metadata_id: null,
                      ds_schema: null,
                    });
                  }}
                  style={{ width: "640px" }}
                >
                  {DBType?.map((item) => {
                    return (
                      <Option value={item} key={item}>
                        {dicCodeInfoList?.DG_DB_TYPE?.map(
                          (value) => value[item]
                        )}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            ) : null}

            <Form.Item
              name="metadata_desc"
              label="描述"
              initialValue={
                addNew
                  ? copiedFlag
                    ? copied?.metadata_desc
                    : null
                  : defaultData?.metadata_desc
              }
            >
              <TextArea
                allowClear
                style={{
                  width: "640px",
                }}
                autoSize={{ minRows: 5 }}
              />
            </Form.Item>

            <div style={{ marginBottom: "8px" }}>标签</div>
            <Select
              style={{
                width: "100%",
                marginBottom: "10px",
              }}
              maxTagCount="responsive"
              placeholder="搜索或添加选项"
              mode="multiple"
              showSearch
              dropdownRender={(menu) => (
                <div className="subjectScopeSearchPoP">
                  {total === 0 ? (
                    <>
                      <div
                        style={{
                          margin: "5px",
                          marginLeft: "12px",
                          cursor: "pointer",
                          color: "#535353",
                        }}
                        onClick={() => {
                          const data = chooseList?.some(
                            (item) => item.label === chooseTag
                          );
                          if (!data) {
                            setChooseList([
                              ...chooseList,
                              {
                                key: Math.random(),
                                label: chooseTag,
                                value: Math.random(),
                              },
                            ]);
                          }
                        }}
                      >
                        添加「 {chooseTag} 」为新选项
                      </div>
                    </>
                  ) : null}
                  {menu}
                </div>
              )}
              value={chooseList}
              filterOption={false}
              labelInValue={true}
              defaultActiveFirstOption={false}
              onSearch={debounce(handleValue, 1000)}
              allowClear
              showArrow="true"
              onChange={(e) => {
                setChooseList(e);
                setTagInfo(tagList);
              }}
              onDropdownVisibleChange={(flag) => {
                if (flag === false) {
                  setTotal(10);
                  setTagInfo(tagList);
                }
              }}
              suffixIcon={
                <ReactSVG
                  src={DropdownSvg}
                  style={{ width: "16px", height: "16px" }}
                ></ReactSVG>
              }
            >
              {tagInfo && tagInfo.length > 0
                ? tagInfo.map((item, index) => (
                    <Option value={item.tag_id} key={index}>
                      {item.tag_name}
                    </Option>
                  ))
                : null}
            </Select>

            <HeaderTitle title="技术信息"></HeaderTitle>

            {metaCode === "WEB" ? (
              <Form.Item
                label="网址"
                name="url"
                rules={[
                  {
                    required: true,
                  },
                ]}
                initialValue={addNew ? null : defaultData?.url}
              >
                <Input
                  allowClear
                  placeholder="1-500个字符"
                  style={{ width: "640px" }}
                />
              </Form.Item>
            ) : null}

            {metaCode === "WECHAT_ACC" ? (
              <Form.Item
                label="名称"
                name="wechat_name"
                rules={[
                  {
                    required: true,
                  },
                ]}
                initialValue={addNew ? null : defaultData?.wechat_name}
              >
                <Input
                  allowClear
                  placeholder="1-500个字符"
                  style={{ width: "640px" }}
                />
              </Form.Item>
            ) : null}

            {metaCode === "API" ? (
              <>
                <Form.Item
                  label="接口URL"
                  name="api_url"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  initialValue={addNew ? null : defaultData?.api_url}
                >
                  <Input
                    allowClear
                    placeholder="1~100个字符"
                    style={{ width: "640px" }}
                  />
                </Form.Item>

                <Form.Item
                  label="请求方式"
                  name="req_method_code"
                  rules={[
                    {
                      required: true,
                      message: "请选择请求方式",
                    },
                  ]}
                  initialValue={addNew ? null : defaultData?.req_method_code}
                >
                  <Select
                    placeholder="请选择"
                    allowClear
                    showSearch={true}
                    suffixIcon={
                      <ReactSVG
                        src={DropdownSvg}
                        style={{ width: "16px", height: "16px" }}
                      ></ReactSVG>
                    }
                    style={{ width: "300px" }}
                  >
                    {reqMethodType?.map((item) => {
                      return (
                        <Option value={item} key={item}>
                          {dicCodeInfoList?.DG_API_REQ_METHOD?.map(
                            (value) => value[item]
                          )}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Content-Type"
                  name="content_type"
                  rules={[
                    {
                      required: true,
                      message: "请选择Content-Type",
                    },
                  ]}
                  initialValue={addNew ? null : defaultData?.content_type}
                >
                  <Select
                    placeholder="请选择"
                    allowClear
                    showSearch={true}
                    suffixIcon={
                      <ReactSVG
                        src={DropdownSvg}
                        style={{ width: "16px", height: "16px" }}
                      ></ReactSVG>
                    }
                    style={{ width: "300px" }}
                  >
                    {contentTypeType?.map((item) => {
                      return (
                        <Option value={item} key={item}>
                          {dicCodeInfoList?.DG_API_CONTENT_TYPE?.map(
                            (value) => value[item]
                          )}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="req_parm_exp"
                  label="请求参数及说明"
                  style={{
                    width: "100%",
                  }}
                  initialValue={addNew ? null : defaultData?.req_parm_exp}
                >
                  <TextArea allowClear autoSize={{ minRows: 30 }} />
                </Form.Item>

                <Form.Item
                  name="resp_exp"
                  label="响应及说明"
                  style={{
                    width: "100%",
                  }}
                  initialValue={addNew ? null : defaultData?.resp_exp}
                >
                  <TextArea allowClear autoSize={{ minRows: 30 }} />
                </Form.Item>
              </>
            ) : null}

            {metaCode === "DS" ? (
              <>
                <Form.Item
                  label="Host"
                  name="db_server"
                  rules={[
                    {
                      required: true,
                      message: "请输入Host，不超过255个字符",
                    },
                    {
                      pattern: /^^(.|\n){0,255}$/,
                      message: "Host不超过255个字符",
                    },
                  ]}
                  initialValue={
                    addNew
                      ? copiedFlag
                        ? copied?.db_server
                        : null
                      : defaultData?.db_server
                  }
                >
                  <Input
                    allowClear
                    placeholder="1~255个字符"
                    style={{ width: "640px" }}
                  />
                </Form.Item>
                <Form.Item
                  label="DataBase"
                  name="db_database"
                  rules={[
                    {
                      required: true,
                      message: "请输入DataBase，不超过64个字符",
                    },
                    {
                      pattern: /^^(.|\n){0,64}$/,
                      message: "DataBase不超过64个字符",
                    },
                  ]}
                  initialValue={
                    addNew
                      ? copiedFlag
                        ? copied?.db_database
                        : null
                      : defaultData?.db_database
                  }
                >
                  <Input
                    allowClear
                    placeholder="1~64个字符"
                    style={{ width: "640px" }}
                  />
                </Form.Item>
                <Form.Item
                  label="Username"
                  name="db_username"
                  rules={[
                    {
                      required: true,
                      message: "请输入Username，不超过32个字符",
                    },
                    {
                      pattern: /^^(.|\n){0,32}$/,
                      message: "Username不超过32个字符",
                    },
                  ]}
                  initialValue={
                    addNew
                      ? copiedFlag
                        ? copied?.db_username
                        : null
                      : defaultData?.db_username
                  }
                >
                  <Input
                    allowClear
                    placeholder="1~32个字符"
                    style={{ width: "640px" }}
                  />
                </Form.Item>
                <Form.Item
                  label="Password"
                  name="db_password"
                  rules={[
                    {
                      required: true,
                      message: "请输入Password，不超过32个字符",
                    },
                    {
                      pattern: /^^(.|\n){0,32}$/,
                      message: "Password不超过32个字符",
                    },
                  ]}
                  initialValue={
                    addNew
                      ? copiedFlag
                        ? copied?.db_password
                        : null
                      : defaultData?.db_password
                  }
                >
                  <Input
                    allowClear
                    placeholder="1~32个字符"
                    style={{ width: "640px" }}
                    type="password"
                  />
                </Form.Item>
                <Form.Item
                  label="Port"
                  name="db_port"
                  rules={[
                    {
                      required: true,
                      message: "请输入Port，限制整数",
                    },
                    {
                      pattern: /^[1-9]\d*$/,
                      message: "Port需要为整数",
                    },
                  ]}
                  initialValue={
                    addNew
                      ? copiedFlag
                        ? copied?.db_port
                        : null
                      : defaultData?.db_port
                  }
                >
                  <InputNumber
                    controls={false}
                    placeholder="请输入整数"
                    style={{ width: "640px" }}
                  />
                </Form.Item>
              </>
            ) : null}

            {metaCode === "TABLE" ? (
              <>
                <Form.Item
                  label="数据存储"
                  name="ds_metadata_id"
                  rules={[
                    {
                      required: true,
                      message: "请选择数据存储",
                    },
                  ]}
                  initialValue={addNew ? null : defaultData?.ds_metadata_id}
                >
                  <Select
                    placeholder="请选择"
                    disabled={newDBTypeCode === null || addNew === false}
                    allowClear
                    showSearch={true}
                    suffixIcon={
                      <ReactSVG
                        src={DropdownSvg}
                        style={{ width: "16px", height: "16px" }}
                      ></ReactSVG>
                    }
                    style={{ width: "300px" }}
                    onChange={(e) => {
                      form.setFieldsValue({
                        ds_schema: null,
                      });
                      setDbCode(e);
                      setDbTableList({});
                      getDbSchemas(e);
                    }}
                  >
                    {MetadataStorageList?.map((item, index) => {
                      return (
                        <Option value={item.metadata_id} key={index}>
                          {`${item.chi_name} / ${item.eng_name}`}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Schema"
                  name="ds_schema"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "请选择Schema",
                  //   },
                  // ]}
                  initialValue={addNew ? null : defaultData?.ds_schema}
                >
                  <Select
                    placeholder="请选择"
                    disabled={newDBTypeCode === null || addNew === false}
                    allowClear
                    showSearch={true}
                    suffixIcon={
                      <ReactSVG
                        src={DropdownSvg}
                        style={{ width: "16px", height: "16px" }}
                      ></ReactSVG>
                    }
                    style={{ width: "300px" }}
                    onChange={(e) => {
                      setSchema(e);
                    }}
                  >
                    {DbSchemasList?.map((item, index) => {
                      return (
                        <Option value={item} key={index}>
                          {item}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="模型定义"
                  style={{ flexWrap: "nowrap" }}
                ></Form.Item>
                {addNew ? (
                  <Button
                    className="selectBtn btn"
                    onClick={() => {
                      AddModal({
                        maskClosable: true,
                        noIcon: true,
                        noBtn: false,
                        title: "选择表",
                        okText: "选择",
                        metaDataType: metaDataType,
                        dicCodeInfoList: dicCodeInfoList,
                        layerList,
                        dbCode,
                        schema,
                        onOk: (value, id) => {
                          setDbTableId(id);
                          setDbTableList(value);
                        },
                      });
                    }}
                    style={{
                      backgroundColor: dbCode === null ? "#DEDEDE" : "#016DB4",
                      marginRight: "340px",
                    }}
                    disabled={dbCode === null}
                  >
                    从数据库中选择表
                  </Button>
                ) : null}

                {/* <Button className="checkBtn btn">查看DDL</Button> */}

                {Object.values(dbTableList).length !== 0 ? (
                  <DetailTable
                    ref={detailTableRef}
                    dbTableList={dbTableList}
                    setDbTableList={setDbTableList}
                  ></DetailTable>
                ) : null}
              </>
            ) : null}

            {metaCode !== "DS" ? (
              <Form.List name="meta_cons">
                {(fields, { add, remove }) => (
                  <>
                    <Form.Item
                      label="血缘关系"
                      style={{
                        width: "calc(100% - 30px)",
                      }}
                    ></Form.Item>
                    <Dropdown overlay={menu} trigger={["click"]}>
                      <PlusCircleFilled
                        style={{
                          fontSize: "18px",
                          color: "#006AB2",
                          height: "18px",
                        }}
                      />
                    </Dropdown>

                    {relationList?.map((item, index) => (
                      <Space key={item.key} align="baseline">
                        <Form.Item {...item} style={{ width: "254px" }}>
                          <ReactSVG
                            src={
                              item.cons_type_code === "SOURCE"
                                ? sourceIcon
                                : flowIcon
                            }
                            style={{
                              width: "16px",
                              height: "16px",
                              marginRight: "8px",
                            }}
                          ></ReactSVG>
                          <div>{`${item.chi_name} / ${item.eng_name}`}</div>
                        </Form.Item>
                        <MinusCircleFilled
                          onClick={() => handleRemoveItem(item)}
                          style={{ color: "#006AB2", fontSize: "18px" }}
                          className="dynamic-delete-button"
                        />
                      </Space>
                    ))}
                  </>
                )}
              </Form.List>
            ) : null}

            <HeaderTitle title="管理信息"></HeaderTitle>

            <Form.Item label="管理责任人">
              <Input
                type="text"
                disabled={true}
                style={{ width: "640px" }}
                value={
                  managementResList?.da_rp_id_value ||
                  managementResList?.def_da_rp_id_value
                }
              />
            </Form.Item>
          </Form>
        </Scrollbars>
      )}
    </Drawer>
  );
};

export default DialogDrawer;
